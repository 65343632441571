/*
 * 업무구분 : 청약
 * 화면 명 : MSPPS510M
 * 화면 설명: 모바일청약 계약관계자 확인
 */
<!--
기존 : MSPPS110M
[2024.03.08 수정사항]
0. 변경한 정보는 고객카드에... 영역 class명(bgcolor-1) 삭제 : w100 bd-b-Ty1 bd-T-Ty1 pal1624 bgcolor-1  -> w100 bd-b-Ty1 bd-T-Ty1 pal1624
1. 그룹화 class명 추가 : pb82 -> card-list-wrap list-ty2 추가
2. class명 변경 : pal2024 lastdiv-full fexTy5 bd-b-Ty1 -> pal2024 firstdiv-full fexTy3-1
3. <template #title> 및 <template #btn> 영역 추가 및 변경
4. class명 변경 : con-area pt30 pb50 bd-b-Ty1 -> con-area pt10 pb30
5. 주민등록번호 class명 변경 : verify-birth mt20 -> verify-birth
6. 피보험자와의 관계 class명 변경 : label-title mt50 -> label-title mt20
7. 세대조정 고객등록동의 버튼 영역 class명 추가 : insert-area full48 -> insert-area full48 pb0
8. 버튼 및 문구 class명 변경 : terms-list-area crTy-bk7 fs14rem mt30 -> terms-list-area crTy-bk7 fs14rem mt20
9. tab 영역 안 class명 변경 : con-area pt30 pb50 bd-b-Ty1 -> con-area pt30 pb30
10. <msp-expand ...>에 펼침 옵션 expanded 추가 

[2024.03.14 수정사항] - 기존 MSPPS110M에 ci에서 개발 예정으로 최종적으로 5월에 반영되어야 하는 건   
  1. 지정대리청구인 드롭박스에서 "간편지정대리청구인" 선택시 지정대리청구인 성명 입력 폼 추가

[2024.03.25 수정사항]
1. pb82 bgcolor-1 과 card-list-wrap list-ty2 로 나눠서 감싸기
2. pb82 bgcolor-1 영역 alignV="start"를 alignH="center" 
-->
<template>
  <ur-page-container class="msp" title="정보확인" :show-title="true" :topButton="true" type="subpage"
  @on-header-left-click="fn_HeaderBackBtnHandler">

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">      
      <div class="w100 bd-b-Ty1 bd-T-Ty1 pal1624"><!-- // class명(bgcolor-1) 삭제 2024.03.08 -->
        <span class="fs14rem crTy-bk7">변경한 정보는 고객카드에 반영되며, 예금주는 필수컨설팅 동의가 유효한 세대원만 선택가능합니다.</span>
      </div>
      <ur-box-container alignH="center" componentid="" direction="column" class="pb82 bgcolor-1"><!-- // alignV="start"를 alignH="center" 변경 2024.03.25 -->

        <ur-box-container direction="column" alignV="start" class="card-list-wrap list-ty2"><!-- // 그룹화 class명 card-list-wrap list-ty2 추가 -->        

          <!-- 계약자 -->
          <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
          <msp-expand ref="contrExp" btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1">
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <!-- 계피동일 인경우 피보험자 문구 표시 -->
                    <mo-badge class="badge-sample-badge green" text="" shape="status">계약자</mo-badge>
                    <mo-badge class="badge-sample-badge blue2" text="" shape="status" v-if="contrInsrdSameYn === 'Y'">피보험자</mo-badge>                  
                    <!-- <mo-badge class="badge-sample-badge blue2" text="" shape="status">주피</mo-badge>
                    <mo-badge class="badge-sample-badge blue" text="" shape="status">종피</mo-badge>
                    <mo-badge class="badge-sample-badge green2" text="" shape="status">예금주</mo-badge>
                    <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자1</mo-badge>
                    <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자2</mo-badge>
                    <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
                    <mo-badge class="badge-sample-badge black" text="" shape="status">FC</mo-badge> 
                    <mo-badge class="badge-sample-badge gray2" text="" shape="status">자녀</mo-badge> -->
                  </div>

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{lv_contr.custNm}}</span>
                      <!-- <mo-badge class="badge-sample-badge sm ml10 lightgreen" text="" shape="status">계약자</mo-badge>
                      <mo-badge class="badge-sample-badge sm ml10 lightblue" text="" shape="status">주피</mo-badge>
                      <mo-badge class="badge-sample-badge sm ml10 lightblue2" text="" shape="status">종피</mo-badge> -->
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt10" v-show="urlTrnsContrShow"> <!-- urlTrnsContrShow 추가/ 231016.ASR231000426 -->
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypContr[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K" >알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T" >바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2" v-if="showHideTooltip===true" @click="showHideTooltip=false"> 
                             FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template> 
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_contr.custNm" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_contr.age" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_contr.custRrnId1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_contr.custRrnId2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>
                <!-- 지정대리청구인_2인가능_수정_START(ASR210500282_2021.05.31 PJO)/퍼블 20210518 수정 지청대리청구인(checkbox->select)-->
              <div class="w100 mt20">
                <div class="label-title">지정대리청구인</div>
                <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                <msp-bottom-select ref="apcPrsonAplSc" :rules="validateRuleapcPrsonAplSc" class="ns-dropdown-sheet btm-error" v-model="apcPrsonAplScSel.key" :itemValue="'key'" :itemText="'label'" :items="apcPrsonAplScList" @selectedItem="fn_ApcPrsonAplScSel_OnClick" :disabled="!isAsgnProxyClamPsb" underline bottom-title="지정대리청구인 선택" closeBtn scrolling placeholder="선택하세요."/>
                <ul class="terms-list-area crTy-bk7 fs14rem mt10" v-if="isAsgnProxyClamPsb">
                  <li><b>지정대리청구인</b>{{apcInfoMsg}}</li>
                  <li v-if="zzInsrTypMgId === 'N01'">{{apcInfoMsg2}}</li> <!-- ASR240200999_간편지대청 도입(김문준/240423) -->
                </ul>
              </div>
              <!-- // 지정대리청구인_2인가능_수정_END(ASR210500282_2021.05.31 PJO) -->
                <!-- 지정대리청구인 1인경우 -->
                <!-- <div class="w100 mt20" v-if="isAsgnProxyClamPsb && isApcAsgnView"> -->
                <div class="w100 mt20" v-if="isApcAsgnView">
                  <div class="label-title">{{apcLblTxt1}}</div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="apcPrsonCust1" class="ns-dropdown-sheet btm-error" v-model="lv_asgnProxyClamInfoSel.key" :itemValue="'key'" :itemText="'label'" :items="householdMembers_apc" @selectedItem="fn_SetApc1" underline bottom-title="지정대리청구인 선택" closeBtn scrolling placeholder="선택하세요."/>
                  <div class="w100 mt50" v-if="isApcAsgnView_default1">                  
                    <div class="label-title mt20">피보험자와의 관계</div><!-- // mt50 -> mt20 -->
                    <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                    <msp-bottom-select ref="apcPrson1Rltn1" :rules="validateRuleapcPrson1Rltn1" class="ns-dropdown-sheet btm-error" v-model="asgnProxyClamContrRltnScCdSel.key" :itemValue="'key'" :itemText="'label'" :items="asgnProxyClamContrRltnScCdList" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요."/>
                    
                    <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                      <mo-button class="ns-btn-round rounded" @click="fn_OpenMSPCM304P">세대조정</mo-button>
                      <mo-button class="ns-btn-round rounded" >고객등록동의</mo-button>
                    </ur-box-container>
                  </div>
                  <!--  ASR240200999_간편지대청 도입(김문준/240423)/start
                      간편BP 채번시 고객명유효값: 개인-내국인 고객명(간편지청 고객명) : 한글 10자 , 영문/숫자/특수문자/공란 불가 -->
                  <div class="w100 mt50" v-if="isApcAsgnView_simple1">
                    <div class="w100 mt50">
                      <div class="label-title mt20">지정대리청구인1 성명</div>
                      <mo-text-field  ref="custNmRef1" class="form-input-name full" maxlength="10" :rules="validateRuleName" underline placeholder="입력하세요!" v-model="apcCustNm_simple1" @blur="fn_CheckCustNm('1')"/>
                    </div>
                    <div class="contain-title mt50">피보험자와의 관계</div>
                    <msp-bottom-select ref="apcPrson1Rltn2" :rules="validateRuleapcPrson1Rltn2" class="ns-dropdown-sheet btm-error" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apclamInfoSel_simple1.key" :items="apcContrRltnScCdList_simple"/>
                  </div>
                  <!-- ASR240200999_간편지대청 도입(김문준/240423)/end-->
                </div>
                <!-- 지정대리청구인 1인경우 end -->
                <!-- 지정대리청구인 2인경우 -->
                <div class="w100 mt20" v-if="isAsgnProxyClamPsb && isApcAsgnView2">
                  <div class="label-title">{{apcLblTxt2}}</div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="apcPrsonCust2" class="ns-dropdown-sheet btm-error" v-model="lv_asgnProxyClamInfoSel2.key" :itemValue="'key'" :itemText="'label'" :items="householdMembers_apc" @selectedItem="fn_SetApc2" underline bottom-title="지정대리청구인 선택" closeBtn scrolling placeholder="선택하세요."/>
                  <div class="w100 mt50" v-if="isApcAsgnView_default2">
                    <div class="label-title mt20">피보험자와의 관계</div><!-- // mt50 -> mt20 -->
                    <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                    <msp-bottom-select ref="apcPrson2Rltn1" :rules="validateRuleapcPrson2Rltn1" class="ns-dropdown-sheet btm-error" v-model="asgnProxyClamContrRltnScCdSel2.key" :itemValue="'key'" :itemText="'label'" :items="asgnProxyClamContrRltnScCdList" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요."/>
                    
                    <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                      <mo-button class="ns-btn-round rounded" @click="fn_OpenMSPCM304P">세대조정</mo-button>
                      <mo-button class="ns-btn-round rounded" >고객등록동의</mo-button>
                    </ur-box-container>
                  </div>
                  <!-- ASR240200999_간편지대청 도입(김문준/240423)/start-->
                  <div class="w100 mt50" v-if="isApcAsgnView_simple2">
                    <div class="w100 mt50">
                      <div class="label-title mt20">지정대리청구인2 성명</div>
                      <mo-text-field  ref="custNmRef2" class="form-input-name full" maxlength="10" :rules="validateRuleName2" underline placeholder="입력하세요!" v-model="apcCustNm_simple2" @blur="fn_CheckCustNm('2')"/>
                    </div>
                    <div class="contain-title mt50">피보험자와의 관계</div>
                    <msp-bottom-select ref="apcPrson2Rltn2" :rules="validateRuleapcPrson2Rltn2" class="ns-dropdown-sheet btm-error" underline bottom-title="피보험자와의 관계 선택" closeBtn scrolling placeholder="선택하세요" :itemValue="'key'" :itemText="'label'" v-model="apclamInfoSel_simple2.key" :items="apcContrRltnScCdList_simple"/>
                  </div>
                  <!-- ASR240200999_간편지대청 도입(김문준/240423)/end-->
                </div>
                <!-- 지정대리청구인 2인경우 end -->
                

              </div>       
            </template>
          </msp-expand>
          
          <!-- 주피보험자 -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" v-if="contrInsrdSameYn !== 'Y'">
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge blue2" text="" shape="status">주피</mo-badge>
                  </div>

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{lv_insured.custNm}}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt10" v-show="urlTrnsInsuredShow">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypInsured[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K" >알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T" >바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2"  v-if="showHideTooltip===true" @click="showHideTooltip=false"> 
                             FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template> 
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.custNm" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.age" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_insured.custRrnId1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_insured.custRrnId2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_insured.custCelno" readonly underline placeholder="휴대폰번호" />
                </div>
                <!-- 피보험자가 태아인경우 [수정]버튼 숨김 -->
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0" v-if="lv_insured.isUnch !== true">
                  <mo-button class="ns-btn-round rounded" @click="fn_showTSSCM122D(lv_insured.chnlCustId)">수정</mo-button>
                </ur-box-container>
              </div>
            </template>
          </msp-expand>

          <!-- 종피보험자 -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" v-if="showAddInsuredRow"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge blue" text="" shape="status">종피</mo-badge>
                  </div>

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{lv_addInsured.name}}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt10" v-show="urlTrnsAddInsuredShow">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypAddInsured[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K" >알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T" >바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2"  v-if="showHideTooltip===true" @click="showHideTooltip=false"> 
                             FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_addInsured.name" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">연령</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_addInsured.age" readonly underline placeholder="연령" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_addInsured.custRrnId1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_addInsured.custRrnId2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_addInsured.custCelno" readonly underline placeholder="휴대폰번호" />
                </div>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click="fn_showTSSCM122D(lv_addInsured.chnlCustId)">수정</mo-button>
                </ur-box-container>
              </div>
            </template>
          </msp-expand>

          <!-- 예금주 -->
          <msp-expand ref="dpstrExp" btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge green2" text="" shape="status">예금주</mo-badge>
                  </div>

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name crTy-orange2" v-if="!lv_dpstrSel.key">선택하세요</span>
                      <span class="name" v-else>{{lv_dpstrSel.label}}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt10" v-show="urlTrnsAddInsuredShow">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypDpstr[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K" >알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T" >바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2"  v-if="showHideTooltip===true" @click="showHideTooltip=false"> 
                             FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title must">성명</div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="dpstrNm" :rules="validateRuleDpstrSelNm" class="ns-dropdown-sheet btm-error" v-model="lv_dpstrSel.key" :itemValue="'key'" :itemText="'label'" :items="householdMemberList" @selectedItem="fn_chkDpstr" :disabled="lv_dpstrDisabled" underline bottom-title="성명" closeBtn scrolling placeholder="선택하세요."/>
                </div>
                <div class="w100 mt20">
                  <div class="label-title must">계약자와의 관계</div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="dpstrRltn" :rules="validateRuleDPstrSelRltn" class="ns-dropdown-sheet btm-error" v-model="dPstrContrFmrtSel.key" :itemValue="'key'" :itemText="'label'" :items="dPstrContrFmrt" @selectedItem="fn_chkDPstrContrFmrt" :disabled="lv_dpstrDisabled" underline bottom-title="계약자와의관계 선택" closeBtn scrolling placeholder="선택하세요."/>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_dpstrSel.knbEncr1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_dpstrSel.knbEncr2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_dpstrSel.celno" readonly underline placeholder="휴대폰번호" />
                </div>
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click="fn_OpenMSPCM304P" :disabled="lv_dpstrDisabled">세대조정</mo-button>
                  <mo-button class="ns-btn-round rounded" v-if="lv_dpstrSel.chnlCustId !== ''" @click="fn_showTSSCM122D(lv_dpstrSel.chnlCustId)">수정</mo-button>
                </ur-box-container>
                <ul class="terms-list-area crTy-bk7 fs14rem mt20"><!-- // mt30 -> mt20 -->
                  <li>위 연락처는 안내메세지 발송 용도로 사용됩니다. 변경이 필요하신 경우 수정 바랍니다.</li>
                </ul>
              </div>
                      
            </template>
          </msp-expand>

          <!-- 친권자1 -->
          <msp-expand ref="prtrExp1" btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" v-if="showPrtr1Row"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자1</mo-badge>
                  </div>

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name crTy-orange2" v-if="!lv_Prtr1Sel.key">선택하세요.</span> 
                      <span class="name" v-else>{{lv_Prtr1Sel.label}}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt10" v-show="urlTrnsPrtr1Show">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypPrtr1[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K" >알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T" >바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2"  v-if="showHideTooltip===true" @click="showHideTooltip=false"> 
                             FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="prtr1SelNm" :rules="validateRulePrtr1SelNm" class="ns-dropdown-sheet btm-error" v-model="lv_Prtr1Sel.key" :itemValue="'key'" :itemText="'label'" :items="householdMemberList" @selectedItem="fn_chkPrtr1" underline bottom-title="성명" closeBtn scrolling placeholder="선택하세요."/>
                </div>
                <div class="w100 mt20">
                  <div class="fexTy3">
                    <span class="dsD label-title must">관계</span>
                    <div>
                      <mo-checkbox size="small mr10" v-model="singlePrtrCheckbox" @input="fn_singlePrtrCheckboxOnClick()">1인친권자</mo-checkbox>
                      <mo-icon icon="msp-question-gray" @click="open"></mo-icon> 
                    </div>
                  </div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="prtr1SelRltn" :rules="validateRulePrtr1SelRltn" class="ns-dropdown-sheet btm-error" v-model="prtr1ContrFmrtSel.key" :itemValue="'key'" :itemText="'label'" :items="prtr1ContrFmrt" @selectedItem="fn_chkPrtrContrFmrt('1')" underline bottom-title="관계 선택" closeBtn scrolling placeholder="선택하세요."/>
                </div>
                <!-- 성명선택시 노출 -->
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_Prtr1Sel.knbEncr1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_Prtr1Sel.knbEncr2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_Prtr1Sel.celno" readonly underline placeholder="휴대폰번호" />
                </div>
                <!-- 성명선택시 노출 end -->
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click="fn_OpenMSPCM304P">세대조정</mo-button>
                  <mo-button class="ns-btn-round rounded" v-if="lv_Prtr1Sel.chnlCustId !== ''" @click="fn_showTSSCM122D(lv_Prtr1Sel.chnlCustId)">수정</mo-button>
                </ur-box-container>
                <ul class="terms-list-area crTy-bk7 fs14rem mt20">
                  <li>위 연락처는 안내메세지 발송 용도로 사용됩니다. 변경이 필요하신 경우 수정 바랍니다.</li>
                </ul>
              </div>

            </template>
          </msp-expand>

          <!-- 친권자2 -->
          <msp-expand ref="prtrExp2" btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" v-if="showPrtr2Row"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge gray" text="" shape="status">친권자2</mo-badge>
                  </div>  

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name crTy-orange2" v-if="!lv_Prtr2Sel.key">선택하세요.</span> 
                      <span class="name" v-else>{{lv_Prtr2Sel.label}}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt10" v-show="urlTrnsPrtr2Show">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypPrtr2[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K" >알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T" >바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2"  v-if="showHideTooltip===true" @click="showHideTooltip=false"> 
                             FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="prtr2SelNm" :rules="validateRulePrtr2SelNm" class="ns-dropdown-sheet btm-error" v-model="lv_Prtr2Sel.key" :itemValue="'key'" :itemText="'label'" :items="householdMemberList" @selectedItem="fn_chkPrtr2" underline bottom-title="성명" closeBtn scrolling placeholder="선택하세요."/>
                </div>
                <div class="w100 mt20">
                  <div class="fexTy3">
                    <span class="dsD label-title must">관계</span>
                    <div>
                      <mo-checkbox size="small mr10" v-model="isCoprPrtrMuagr" @input="fn_chkCoprPrtrMuagr">공동친권</mo-checkbox>
                    </div>
                  </div>
                  <!-- 만약 msp-bottom-select 에 error validation 이 필요하면 btm-error 클래스를 추가해주세요 -->
                  <msp-bottom-select ref="prtr2SelRltn" :rules="validateRulePrtr2SelRltn" class="ns-dropdown-sheet btm-error" v-model="prtr2ContrFmrtSel.key" :itemValue="'key'" :itemText="'label'" :items="prtr2ContrFmrt" @selectedItem="fn_chkPrtrContrFmrt('2')" underline bottom-title="관계 선택" closeBtn scrolling placeholder="선택하세요."/>
                </div>
                <!-- 성명선택시 노출 -->
                <div class="w100 mt20">
                  <div class="label-title">주민등록번호</div>
                  <div class="verify-birth">
                    <mo-text-field ref="error" underline class="birth-input1 form-input-name w100" v-model="lv_Prtr2Sel.knbEncr1" readonly placeholder="생년월일" error-message="Error message here"/>
                    <span>-</span>
                    <mo-text-field ref="error" underline class="birth-input2 form-input-name w100" v-model="lv_Prtr2Sel.knbEncr2" readonly placeholder="●  ●  ●  ●  ●  ●" error-message="Error message here"/>
                  </div>
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_Prtr2Sel.celno" readonly underline placeholder="휴대폰번호" />
                </div>
                <!-- 성명선택시 노출 end -->
                <ur-box-container alignV="start" componentid="" direction="row" class="insert-area full48 pb0">
                  <mo-button class="ns-btn-round rounded" @click="fn_OpenMSPCM304P">세대조정</mo-button>
                  <mo-button class="ns-btn-round rounded" v-if="lv_Prtr2Sel.chnlCustId !== ''" @click="fn_showTSSCM122D(lv_Prtr2Sel.chnlCustId)">수정</mo-button>
                </ur-box-container>
                <ul class="terms-list-area crTy-bk7 fs14rem mt20">
                  <li>위 연락처는 안내메세지 발송 용도로 사용됩니다. 변경이 필요하신 경우 수정 바랍니다.</li>
                </ul>
              </div>
            </template>
          </msp-expand>

          <!-- 수익자 (계약자) -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" v-if="isBnfrInfo31 || isBnfrInfo32 || isBnfrInfo33 || isBnfrInfo39"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
                  </div>  

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{ bnfrInfo1_custNm }}</span>
                      <mo-badge class="badge-sample-badge sm ml10 lightgreen" text="" shape="status">계약자</mo-badge>
                    </div>
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <mo-tab-box :defaultIdx="isBnfrInfo31 ? '1' : (isBnfrInfo32 ? '2' : (isBnfrInfo33 ? '3' : '4'))" class="ns-move-tab-box tween w100">
                <mo-tab-label idx="1" :disable="!isBnfrInfo31" v-show="isBnfrInfo31">만기</mo-tab-label>
                <mo-tab-label idx="2" :disable="!isBnfrInfo32" v-show="isBnfrInfo32">분할</mo-tab-label>
                <mo-tab-label idx="3" :disable="!isBnfrInfo33" v-show="isBnfrInfo33">연금</mo-tab-label>
                <mo-tab-label idx="4" :disable="!isBnfrInfo39" v-show="isBnfrInfo39">연금후</mo-tab-label>
                <mo-tab-content idx="1">
                  <!-- tab1 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo31.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo31.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth">
                        <mo-text-field v-model="bnfrInfo31.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo31.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo31.shareVl" class="form-input-name full" readonly underline placeholder="지급률(%)" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="2">
                  <!-- tab2 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo32.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo32.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth">
                        <mo-text-field v-model="bnfrInfo32.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo32.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo32.shareVl" class="form-input-name full" readonly underline placeholder="지급률(%)" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="3">
                  <!-- tab3 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo33.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo33.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth">
                        <mo-text-field v-model="bnfrInfo33.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo33.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo33.shareVl" class="form-input-name full" readonly underline placeholder="지급률(%)" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="4">
                  <!-- tab4 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo39.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo39.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth">
                        <mo-text-field v-model="bnfrInfo39.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo39.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo39.shareVl" class="form-input-name full" readonly underline placeholder="지급률(%)" />
                    </div>
                  </div>
                </mo-tab-content>      
              </mo-tab-box>        
            </template>
          </msp-expand>

          <!-- 수익자 (주피) -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1" v-if="isBnfrInfo34 || isBnfrInfo35"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge purple2" text="" shape="status">수익자</mo-badge>
                  </div>  

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{ bnfrInfo2_custNm }}</span>
                      <mo-badge class="badge-sample-badge sm ml10 lightblue" text="" shape="status">주피</mo-badge>
                    </div> 
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <mo-tab-box :defaultIdx="isBnfrInfo34 ? '1' : '2'" class="ns-move-tab-box tween w100">
                <mo-tab-label idx="1" :disable="!isBnfrInfo34" v-show="isBnfrInfo34">상해</mo-tab-label>
                <mo-tab-label idx="2" :disable="!isBnfrInfo35" v-show="isBnfrInfo35">사망</mo-tab-label>
                <mo-tab-content idx="1">
                  <!-- tab1 -->
                  <div class="con-area pt30 pb30">
                    <div class="w100">
                      <div class="label-title">성명</div>
                      <mo-text-field v-model="bnfrInfo34.custNm" class="form-input-name full" readonly underline placeholder="성명" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">연령</div>
                      <mo-text-field v-model="bnfrInfo34.age + '세'" class="form-input-name full" readonly underline placeholder="연령" />
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">주민등록번호</div>
                      <div class="verify-birth">
                        <mo-text-field v-model="bnfrInfo34.knbEncr1" readonly underline class="birth-input1 form-input-name w100" placeholder="생년월일"/>
                        <span>-</span>
                        <mo-text-field v-model="bnfrInfo34.knbEncr2" readonly underline class="birth-input2 form-input-name w100" placeholder="●  ●  ●  ●  ●  ●" />
                      </div>
                    </div>
                    <div class="w100 mt20">
                      <div class="label-title">지급률(%)</div>
                      <mo-text-field v-model="bnfrInfo34.shareVl" class="form-input-name full" readonly underline placeholder="지급률(%)" />
                    </div>
                  </div>
                </mo-tab-content>
                <mo-tab-content idx="2">
                  <!-- tab2 -->
                  <div class="con-area pt30 pb30">
                    <div class="text-blue-box bgcolor-7 pt16 pb16 mb10" v-for="(bnfrInfo, index) in bnfrInfo35" :key="index">
                      <div class="fexTy5">
                        <span class="fs18rem ml0 flex-1 fwm">{{bnfrInfo.custNm}}</span>
                        <mo-button class="ns-btn-round h28 blue-line" v-if="bnfrInfo.bnfrSno === 1 && isAmlDthBnfr01" @click="fn_showTSSCM122D(amlDthBnfr01_chnlCustId)">수정</mo-button>
                      </div>
                      <span class="dsD ml0 mt10"><span class="fs14rem ml0 crTy-bk7 min100">주민등록번호</span>{{bnfrInfo.knbEncr1}}-{{bnfrInfo.knbEncr2}}</span>
                      <span class="dsD ml0 mt4"><span class="fs14rem ml0 crTy-bk7 min100">연령</span>{{bnfrInfo.age}}세</span>
                      <span class="dsD ml0 mt4" v-if="bnfrInfo.cusDtlTypCd === '11'"><span class="fs14rem ml0 crTy-bk7 min100">내외국인구분</span>내국인</span>
                      <span class="dsD ml0 mt4" v-if="bnfrInfo.cusDtlTypCd === '12'"><span class="fs14rem ml0 crTy-bk7 min100">내외국인구분</span>외국인</span>
                      <span class="dsD ml0 mt4"><span class="fs14rem ml0 crTy-bk7 min100">지급률</span>{{bnfrInfo.shareVl}}%</span>
                      <ul class="terms-list-area crTy-orange2 fs14rem mt10" v-if="bnfrInfo.bnfrSno === 1 && isAmlDthBnfr01">
                        <li>AML 대상입니다. 고객정보를 확인해 주세요.</li>
                      </ul>
                    </div>

                  </div>
                </mo-tab-content>
              </mo-tab-box>        
            </template>
          </msp-expand>

          <!-- FC -->
          <msp-expand btn-area-first title-first expanded expand-only-btn class="mo-list-expand w100" btn-area-class="pal2024 firstdiv-full fexTy3-1"> 
            <template #title>
              <div class="list-item__contents overflow-v">
                <div class="list-item__contents__title overflow-v">
                  <div class="badge-area">
                    <mo-badge class="badge-sample-badge black" text="" shape="status">FC</mo-badge>
                  </div>  

                  <!-- 고객 미선택 case -->
                  <!-- <div class="ml10">
                    <span class="fwn fs19rem crTy-bk7">선택하세요</span>
                  </div>  -->
                  <!-- // 고객 미선택 case -->

                  <!-- 고객 선택 case -->
                  <div class="ml10">
                    <div>
                      <span class="name">{{ lv_fc.fcNm }}</span>
                    </div>
                    <ur-box-container alignV="start" componentid="" direction="column" class="ns-segment w100calc50 mt10">
                      <mo-segment-wrapper solid primary v-model="urlTrnsTypFC[0].key" class="btn-type-wrap h28">
                        <mo-segment-button class="mr10" value="K" >알림톡</mo-segment-button>
                        <!-- 툴팁 추가 //2025.01 툴팁추가  -->
                        <div class="tooltipWrap">
                          <mo-segment-button class="mr10" value="T" >바로열기</mo-segment-button>
                          <div class="chat-bubble type-M1 type2"  v-if="showHideTooltip===true" @click="showHideTooltip=false"> 
                            FC 태블릿이나 휴대폰에서<br>고객과 함께 진행 할 수 있어요.
                            <mo-icon icon="msp-close-bold" class="chat-bubble-close" />
                          </div>
                        </div>
                        <!-- 툴팁 추가 끝 //2025.01 툴팁추가  -->
                      </mo-segment-wrapper>
                    </ur-box-container>  
                  </div>  
                  <!-- // 고객 선택 case -->
                </div>
              </div>
            </template>
            <template #btn>
              <mo-button class="link-arrow">
                <mo-icon icon="expand_menu" class="expand__icon" />                  
                <span class="open">접기</span>
                <span class="close">열기</span>
              </mo-button>
            </template>
            <template #content>
              <div class="con-area pt10 pb30">
                <div class="w100">
                  <div class="label-title">성명</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_fc.fcNm" readonly underline placeholder="성명" />
                </div>
                <div class="w100 mt20">
                  <div class="label-title">휴대폰번호</div>
                  <mo-text-field  class="form-input-name full" v-model="lv_fc.fcCelno" readonly underline placeholder="휴대폰번호" />
                </div>
              </div>       
            </template>
          </msp-expand>

        </ur-box-container>

      </ur-box-container>  
    </ur-box-container>
    <!-- content 영역 -->

    <template>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" v-if="authPass" @click="fn_mobpsPbl('N')">다음 (알림톡X)</mo-button>
          <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" @click="fn_mobpsPbl('Y')" >다음</mo-button>
        </div>
      </ur-box-container>
    </template>


    <!-- Popup001 -->
    <mo-bottom-sheet ref="nsbottomsheet"  :close-btn="true"  class="ns-bottom-sheet closebtn">
      <template v-slot:title>
        공동친권, 1인친권자
      </template>
      
      <div class="content-area">
        <div>
          <span class="dsD fs18rem fwb">공동친권</span>
          <span class="dsD fs17remb mt10">다른 법정대리인과의 합의 하에 공동으로 친권을 행사함</span>
        </div>
        <div class="mt30">
          <span class="dsD fs18rem fwb">1인친권자</span>
          <span class="dsD fs17remb mt10">계약자 또는 피보험자의 친권을 단독으로 행사함</span>
        </div>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="close" disabled name="다음">다음</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 지정대리청구인  미지정/지정불가 선택시 // ASR241100645_간편 지정대리청구인 넛지 (241203_PJO) -->
    <mo-bottom-sheet ref="refApcAlertFlag" class="ns-bottom-sheet msp-bottomSt-alert">
      <div class="customer-info-txt txt-center">
        <p class="ment" v-html="asgmMsg"></p>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ApcAlert1" name="아니오">미지정/지정불가</mo-button>
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ApcAlert2" name="예">지정</mo-button>
        </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
    
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'
import Msg from '@/systems/webkit/msg/msg'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import PSCommUtil from '@/ui/ps/comm/PSCommUtil'
import PSConstants from '@/config/constants/psConstants'
import PSDateUtil from '@/ui/ps/comm/PSDateUtil'
import PSServiceManager from '@/ui/ps/comm/PSServiceManager' 
import PSAmlUtil from '@/ui/ps/comm/PSAmlUtil'

import MSPCM300P from '@/ui/cm/MSPCM300P'
import MSPCM304P from '@/ui/cm/MSPCM304P' // 세대조정 조회 및 등록
import PSElstUtil from '@/ui/ps/comm/PSElstUtil' // 전자서명 시작 관련 서비스 유틸


export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS510M', 
  screenId: 'MSPPS510M', 
  components: {
    'MSPCM300P': MSPCM300P,
    'MSPCM304P': MSPCM304P,
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
  },//props

  /***********************************************************************************
  * 팝업일때 팝업 활성 형태 속성 
  * - 풀 팝업 형태 일때 mode 값 설정
  ***********************************************************************************/
  /*
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  */

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      stndKeyList : [],
      isAmlDthBnfr01 : false, //사망수익자 AML대상여부
      amlDthBnfr01_chnlCustId : '', // 사망수익자1의 채널고객ID
      amlBnfrInfoList : {},
      bnfrInfo31 : {}, //만기수익자
      bnfrInfo32 : {}, //분할수익자
      bnfrInfo33 : {}, //연금수익자
      bnfrInfo34 : {}, //상해수익자
      bnfrInfo35 : [], //사망수익자 (최대4명)
      bnfrInfo39 : {}, //연금후수익자
      isBnfrInfo31 : false,
      isBnfrInfo32 : false,
      isBnfrInfo33 : false,
      isBnfrInfo34 : false,
      isBnfrInfo35 : false,
      isBnfrInfo39 : false,
      bnfrInfo1_custNm : '',  //수익자명 (계약자)
      bnfrInfo2_custNm : '',  //수익자명 (주피)
      componentKey: 0,
      //title: '모바일청약',
      lv_InputData: {}, // 발행 데이터
      hoshInfoList: '', // 계약자/피보험자 정보
      planInfoData: '', // 상품정보
      jsonString: '',
      standardAge19: 19, // 기준 나이 19 / 미성년자
      // standardAge15: 15, // 기준 나이 15 / 본인서명불가 나이
      standardAge15: 14, // 기준 나이 15 / 본인서명불가 나이 : 210218기준나이변경
      contrInsrdSameYn: 'N', // 계피상이여부
      //showPopupTSSCM121M: false, // 세대조정 팝업창 호출 여부
      isSendKakao: 'Y', // 카카오 알림톡 발송여부
      isAsgnProxyClamPsb: false, // 지정대리청구인 지정가능여부
      showAddInsuredRow: false, // 종피보험자 표시여부
      showPrtr1Row: false, // 친권1 표시여부
      showPrtr2Row: false, // 친권2 표시여부
      //isTSSCM122D: false, // TSSCM122D : 개인정보 수정 팝업
      //tsscm122DCnsltNo: '',
      //tsscm122DChnlCustId: '',
      showHideTooltip: true, //2025.01 툴팁추가 

      householdMemberList: [ // 세대원 목록
        {
          key: '', // 순번
          label: '', // 이름 4글자
          name: '', // 이름
          chnlCustId: '', // 채널고객ID
          knbEncr: '', // 주민등록번호 암호화 : YYMMDD1******
          knbEncr1: '', // 주민번호 앞자리
          knbEncr2: '', // 주민번호 뒷자리
          celno: '', // 연락처
          isMcnstYn: 'N', // 필수컨설팅동의여부
          style: true // 스타일 , 필수컨설팅동의:N -> true
        }
      ],

      householdMemberListNotContr: [ // 세대원 목록 (계약자 제외)
        {
          key: '', // 순번
          label: '', // 이름 4글자
          name: '', // 이름
          chnlCustId: '', // 채널고객ID
          knbEncr: '', // 주민등록번호 암호화 : YYMMDD1******
          knbEncr1: '', // 주민번호 앞자리
          knbEncr2: '', // 주민번호 뒷자리
          celno: '', // 연락처
          isMcnstYn: 'N', // 필수컨설팅동의여부
          style: true // 스타일 , 필수컨설팅동의:N -> true
        }
      ],

      // 계약자
      lv_contr: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        custCelno: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      // 피보험자
      lv_insured: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false, // 이름이 4자이상인 경우 toolTip표시
        isUnch: false // 태아여부
      },

      // 종피보험자
      lv_addInsured: {
        custNm: '',
        age: '',
        custRrnId: '',
        custRrnId1: '',
        custRrnId2: '',
        chnlCustId: '',
        chnlCstgrId: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      // 예금주
      showDpstrMcnstConfirm: false, // 예금주필수컨설팅 표시
      dPstrContrFmrt: [ // 예금주 계약자와의 관계 : 표시
        {key: '01', label: '본인'},
        {key: '02', label: '배우자'},
        {key: '04', label: '부모'},
        {key: '03', label: '자녀'}
      ],
      lv_dpstrSel: { // 선택한 예금주
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        contrFmrt: '', // 계약자/예금주관계ID
        custCelno: '',
        custRrnId: '',
        contrDpstrSameYn: '', // 계약자예금주동일여부
        insrdDpstrSameYn: '', // 피보험자예금주동일여부
        dpstrIdpnYn: '', // 예금주독립여부
        knbEncr: '',
        key: '', // fdp-select
        label: '' // fdp-select
      },
      dPstrContrFmrtSel: { // 선택한 예금주-계약자와의 관계
        key: '',
        label: ''
      },
      isDpstrChange: false, // 예금주 계약자 동일/상이 여부

      // 친권1
      lv_Prtr1Sel: { // 선택한 친권1
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        contrFmrt: '', // 계약자/예금주관계ID
        custCelno: '',
        custRrnId: '',
        contrDpstrSameYn: '', // 계약자예금주동일여부
        insrdDpstrSameYn: '', // 피보험자예금주동일여부
        dpstrIdpnYn: '', // 예금주독립여부
        knbEncr: '',
        key: '', // fdp-select
        label: '' // fdp-select
      },
      prtr1ContrFmrt: [ // 친권1 계약자관계구분코드
        {key: '20', label: '부'},
        {key: '21', label: '모'}
      ],
      prtr1ContrFmrtSel: { // 선택한 예금주-계약자와의 관계
        key: '',
        label: ''
      },

      // 친권2
      lv_Prtr2Sel: { // 선택한 친권1
        custNm: '', // 이름
        chnlCustId: '', // 채널고객ID
        contrFmrt: '', // 계약자/예금주관계ID
        custCelno: '',
        custRrnId: '',
        contrDpstrSameYn: '', // 계약자예금주동일여부
        insrdDpstrSameYn: '', // 피보험자예금주동일여부
        dpstrIdpnYn: '', // 예금주독립여부
        knbEncr: '',
        key: '', // fdp-select
        label: '' // fdp-select
      },
      prtr2ContrFmrt: [ // 친권2 계약자관계구분코드
        {key: '20', label: '부'},
        {key: '21', label: '모'}
        // {key: '90', label: '1인친권자'}
      ],
      prtr2ContrFmrtSel: { // 선택한 예금주-계약자와의 관계
        key: '',
        label: ''
      },

      //isPrtrShow: false, // 친권 1,2 지정확인
      prtrShowMessage: '미성년자 계약입니다.<br>법정대리인으로 친권인 선택에 유의바랍니다.<br><br>진행하시겠습니까?', // 친권 1,2 확인 문구
      isPrtrShowId: '', // 선택한 친권 1,2

      //isOnePersonPrtrShow: false, // 1인친권자 확인
      onePersonPrtrShowMessage: '미성년자 포함 설계시 미성년자의 법정대리인이<br>2인인 경우 \'단독친권\'은 선택불가합니다. <br><br>진행하시겠습니까?', // 1인친권자 확인 문구

      isCoprPrtrMuagr: false, // 공동친권합의 여부
      //isCoprPrtrMuagrShow: false, // 공동친권합의 확인
      coprPrtrMuagrMessage: '미성년자 포함 설계시 다른 법정대리인과 합의가 된<br> 경우만 \'공동친권\'을 선택할 수 있습니다.<br><br>진행하시겠습니까?', // 공동친권합의 문구

      prtrMcnstConfirmNo: '0', // 친권자 필수컨설팅대상 No
      showPrtrMcnstConfirm: false, // 친권자 필수컨설팅 표시여부

      lv_fc: { // fc
        fcNm: '', // FC 이름
        fcCelno: '',
        fcCelno2: '',
        showNameTooltip: false // 이름이 4자이상인 경우 toolTip표시
      },

      urlTrnsTypList: [ // URL전송유형 목록
        {
          key: 'K',
          label: '알림톡'
        },
        {
          key: 'T',
          label: '바로열기'
        }
      ],
      urlTrnsContrShow: false, // 계약자 청약URL표시여부
      urlTrnsTypContr: [{ // 계약자 URL전송유형
        key: 'K',
        label: '알림톡'
      }],
      
      urlTrnsInsuredShow: false, // 피보험자 청약URL표시여부
      urlTrnsTypInsured: [{ // 피보험자 URL전송유형
        key: 'K',
        label: '알림톡'
      }],
      
      urlTrnsAddInsuredShow: false, // 종피보험자 청약URL표시여부
      urlTrnsTypAddInsured: [{ // 종피보험자 URL전송유형
        key: 'K',
        label: '알림톡'
      }],
      
      urlTrnsDpstrShow: false, // 예금주 청약URL표시여부
      urlTrnsTypDpstr: [{ // 예금주 URL전송유형
        key: 'K',
        label: '알림톡'
      }],
      
      urlTrnsPrtr1Show: false, // 친권1 청약URL표시여부
      urlTrnsTypPrtr1: [{ // 친권1 URL전송유형
        key: 'K',
        label: '알림톡'
      }],
      
      urlTrnsPrtr2Show: false, // 친권2 청약URL표시여부
      urlTrnsTypPrtr2: [{ // 친권2 URL전송유형
        key: 'K',
        label: '알림톡'
      }],
      
      urlTrnsTypFC: [{ // FC URL전송유형
        key: 'K',
        label: '알림톡'
      }],
      
      // 알림톡알림발송순번
      kakaoIapDspchSnContr: 0, // 계약자
      kakaoIapDspchSnInsured: 0, // 피보험자
      kakaoIapDspchSnAddInsured: 0, // 종피보험자

      kakaoIapDspchSnDpstr: 0, // 예금주
      kakaoIapDspchSnPrtr1: 0, // 친권1
      kakaoIapDspchSnPrtr2: 0, // 친권2

      // 지정대리청구인
      validateRuleapcPrsonAplSc: [
        v => !(this.apcPrsonAplScSel.key === '' || this.apcPrsonAplScSel.key === '00') || '필수입니다. 선택하세요.'
      ],
      // 지정대리청구인1
      validateRuleapcPrson1Rltn1: [
        v => !(this.asgnProxyClamContrRltnScCdSel.key === '' || this.asgnProxyClamContrRltnScCdSel.key === '00') || '필수입니다. 선택하세요.'
      ],
      validateRuleapcPrson1Rltn2: [
        v => !(this.apclamInfoSel_simple1.key === '') || '필수입니다. 선택하세요.'
      ],
      // 지정대리청구인2
      validateRuleapcPrson2Rltn1: [
        v => !(this.asgnProxyClamContrRltnScCdSel2.key === '' || this.asgnProxyClamContrRltnScCdSel2.key === '00') || '필수입니다. 선택하세요.'
      ],
      validateRuleapcPrson2Rltn2: [
        v => !(this.apclamInfoSel_simple2.key === '') || '필수입니다. 선택하세요.'
      ],

      // 예금주
      validateRuleDpstrSelNm: [
        v => !(this.lv_dpstrSel.key === '') || '필수입니다. 선택하세요.'
      ],
      validateRuleDPstrSelRltn: [
        v => !(this.dPstrContrFmrtSel.key === '') || '필수입니다. 선택하세요.'
      ],

      // 친권자1
      validateRulePrtr1SelNm: [
        v => !(this.lv_Prtr1Sel.key === '') || '필수입니다. 선택하세요.'
      ],
      validateRulePrtr1SelRltn: [
        v => !(this.prtr1ContrFmrtSel.key === '') || '필수입니다. 선택하세요.'
      ],

      //친권자2
      validateRulePrtr2SelNm: [
        v => !(this.lv_Prtr2Sel.key === '') || '필수입니다. 선택하세요.'
      ],
      validateRulePrtr2SelRltn: [
        v => !(this.prtr2ContrFmrtSel.key === '') || '필수입니다. 선택하세요.'
      ],

      kakaoIapDspchSnFc: 0, // FC

      // 계약자 관계구분 코드
      mobpsContrRltnScCd: [
        {key: '01', label: '본인'},
        {key: '02', label: '배우자'},
        {key: '03', label: '자녀'},
        {key: '04', label: '부모'},
        {key: '05', label: '형제자매'},
        {key: '06', label: '조부모'},
        {key: '07', label: '외조부모'},
        {key: '08', label: '손자(녀)'},
        {key: '09', label: '시부모'},
        {key: '10', label: '장인장모'},
        {key: '11', label: '며느리, 사위'},
        {key: '18', label: '친척'}
        // {key: '19', label: '그 외 타인'},
        // {key: '90', label: '1인친권자'},
        // {key: '91', label: '의견합의'}
      ],

      //showAsgnProxyClamConfirm: false, // 지정대리청구인 필수컨설팅
      isApcAsgnView: false, // 지정대리청구인표시여부 // asis : isAsgnProxyClamShow--> tobe : isApcAsgnView   (TSSPS111P와 맞추기외해 변경함.)
      lv_asgnProxyClamInfoSel: { // 선택한 지정대리청구
        key: '00',
        label: ''
      },
      asgnProxyClamContrRltnScCdList: [ // 지정대리청구인-피보험자의관계
        {key: '04', label: '부모'},
        {key: '02', label: '배우자'},
        {key: '03', label: '자녀'},
        // 05:기타가족
        // 06:친족
        {key: '61', label: '조부모'},
        {key: '62', label: '손자'},
        {key: '63', label: '형제'},
        {key: '64', label: '삼촌'},
        {key: '65', label: '외삼촌'},
        {key: '66', label: '고모'},
        {key: '67', label: '이모'},
        {key: '68', label: '조카'}
        // 통합코드:계약자관계코드
        // FC선택가능한 관계 N:1 통합코드 로 매핑함
      ],
      asgnProxyClamContrRltnScCdSel: { // 선택한 지정대리청구인-계약자와의 관계
        key: '00',
        label: ''
      },
      isMobpsValCheck: false, // 모바일청약 값검증 여부
      isMobpsValMessge: '', // 모바일청약 값검증 표시
      isMobpsPblEnd: false, // 모바일청약발행Confirm표시
      mobpsPblEndMessage: '모바일청약을 발송하였습니다.<br><br>※ [진행조회]에서 진행현황을 조회하실 수 있습니다.',
      authPass: false, // 운영계 외의 서버에서 인증 패스 할 수 있도록 하는 변수
      // 지정대리청구_보완 (2021.05.31_PJO)_START
      isApcAsgnView2: false, // 지정대리청구인 표시여부(2인)
      zzInsrTypMgId: '', // 보험유형관리코드RD
      apcInfoMsg: '', // 지정대리청구인 안내 메세지
      apcPrsonAplScSel: {key: '', label: ''}, // 기본값 ( 선택시.여기세팅 )
      apcPrsonAplScCd: 'M1', // 지정대리청구인신청구분코드
      apcPrsonAplScList: [ // 지정대리청구인신청구분리스트 01(1인지정), 02(2인지정), M1(미지정), M2(지정불가)
        {key: '01', label: '1인지정'},
        {key: '02', label: '2인지정'},
        {key: 'M1', label: '미지정(계약자요청)'},
        {key: 'M2', label: '지정불가(약관상 자격 갖춘 자 없음)'}
      ],
      apcLblTxt1: '지정대리청구인', // 지정대리청구인 라벨 표기 (1인 : 지정대리청구인, 2인 : 지정대리청구인1(대표)
      apcLblTxt2: '지정대리청구인2', // 지정대리청구인 라벨 표기2 (2인일경우만 표기됨)
      lv_asgnProxyClamInfoSel2: { // 선택한 지정대리청구2
        key: '00',
        label: ''
      },
      asgnProxyClamContrRltnScCdSel2: { // 선택한 지정대리청구인-계약자와의 관계
        key: '00',
        label: ''
      },
      lv_dpstrDisabled: false, //  예금주(명,계약자와의관계,세대조정) disabled 여부 ( true 면 비활성화됨 / 예금주 변경불가 )
      // ASR211000708_지정대리청구 로직 수정 및 신규 팝업 반영_사랑온 가입설계_20211109_youngki78.lee
      //isShowAlert: false, // 공통 메시지 팝업 출력
      //msgDesc: '', // 공통 메시지 팝업 내용
      singlePrtrCheckbox: false, // 1인친권자 선택여부
      // ASR240200999_간편지대청 도입(김문준/240423)-------------------start
      show1: true, // 계약자 탭
      apcInfoMsg2: '', // 지정대리청구인 안내 메세지 // ASR240200999_간편지대청 도입(김문준/240423)
      householdMembers_apc: [], // 지정대리용_세대원 목록 (계약자 제외)
      apcContrRltnScCdList_simple: [ // 간편_지정대리청구인-계약자와의관계
        {key: '11', label: '배우자'},
        {key: '12', label: '아들'},
        {key: '13', label: '딸'}
      ],
      apclamInfoSel_simple1: { // 간편_지정대리청구인-계약자와의관계 선택값
        key: '',
        label: ''
      },
      apclamInfoSel_simple2: { // 간편_지정대리청구인-계약자와의관계 선택값
        key: '',
        label: ''
      },
      isApcAsgnView_default1 : false, // 지대청1.고객카드선택_관계선택
      isApcAsgnView_default2 : false, // 지대청2.고객카드선택_관계선택
      isApcAsgnView_simple1 : false, // 간편지대청1.성명입력_관계선택
      isApcAsgnView_simple2 : false, // 간편지대청2.성명입력_관계선택
      apcCustNm_simple1 : '',
      apcCustNm_simple2 : '',
      simpAsgnClamAgntCd01 : '', // 지정대리인1_입력유형 / A 일반, B 간편
      simpAsgnClamAgntCd02 : '', // 지정대리인2_입력유형 / A 일반, B 간편
      custNmResult: true, // 이름 유효성 체크 결과
      custNmErrorMsg: '', // 이름 유효성 결과 에러 메시지
      custNmResult2: true, // 이름 유효성 체크 결과
      custNmErrorMsg2: '', // 이름 유효성 결과 에러 메시지
      validateRuleName: [
        v => !!v || '입력하세요.',
        v => this.custNmResult || this.custNmErrorMsg
      ],
      validateRuleName2: [
        v => !!v || '입력하세요.',
        v => this.custNmResult2 || this.custNmErrorMsg2
      ],
      // ASR240200999_간편지대청 도입(김문준/240423)-------------------end
      // 초회보험료 신용카드 (ASR240400333_24.05 장한나)
      CARD_YN_CHK : 'N' // 업무컨피그(초회보험료 신용카드)=Y &&  보험료납입유형값RD=C 인경우 가능
      ,asgmMsg: '지정대리청구인을 설정하면<br>보험금수령이 용이합니다.<br>&quot;이름/관계&quot;만 입력하면 간단하게 설정되는<br>간편지정대리청구인을 지정하시겠습니까?' // ASR241100645_간편 지정대리청구인 넛지 (241203_PJO)
    }
  },//data

  /***********************************************************************************
  * 컴포넌트 BASE 라우터 네비게이션 가드 함수 정의영역
  * - 화면전환 Route Life Cycle 함수
  * - 주로 next(fals) 진행불가처리 ,next()진행 처리 스크립트 컨트롤용도
  *   사용시 함수 주석 풀고 스크립트 작성 할것  
  ***********************************************************************************/
  
  //router에 의해 화면진입시 호출 인스턴스 생성되기전이라 this 자원 접근안됨  
  //beforeRouteEnter (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteEnter

  //router에 의해 화면을 벗어날때 호출 
  //beforeRouteLeave (to, from, next) { 반드시  next(fals) OR next()  호출 },//beforeRouteLeave


 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    // backButton event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)
    
    this.lv_InputData = this.$route.params
    //console.log('★★★★ MSPPS510M 받은 파라미터!!')
    //console.log(this.lv_InputData)

    // 검증계면 인증 패스 로직 유효 local/development/stage/production
    this.authPass = (process.env.NODE_ENV !== 'production')

    if (this.$route.params) {
      this.lv_InputData = this.$route.params

      // 지정대리청구인 지정가능여부 // 위치변경 
      this.isAsgnProxyClamPsb = this.lv_InputData.isAsgnProxyClamPsb
      if ( this.isAsgnProxyClamPsb ) {
        // 보험유형관리코드RD (2021.05.31_PJO)
        this.zzInsrTypMgId = this.lv_InputData.zzInsrTypMgId
        this.fn_Init_ApcAsgn()
      }

      // ASR210800036 즉시연금 자동송금 세팅 기준 변경(김선모/2021.08.17 PJO)
      // 태아보험(특이보험유형코드RD=29) & 상품보험관계구성코드RD=60(태아_적립형주보험)有인 경우 , 계약자 예금주 동일한 경우만 전자서명진행가능
      // 전자서명   : ASIS TSSPS141D 계좌선택시 블러킹 -> TOBE TSSPS111P 계약자 !=예금주 선택 못하도록 수정
      // 모바일청약 : ASIS           계좌선택시 블러킹 -> TOBE TSSPS112P 계약자 !=예금주 선택 못하도록 수정
      let ptclrPrdTId = (this.lv_InputData.zzPtclrPrdTId === undefined || this.lv_InputData.zzPtclrPrdTId === '') ? ' ' : this.lv_InputData.zzPtclrPrdTId // 특이상품유형값
      let pdinsRCCId = (this.lv_InputData.zzPdinsRCCId === undefined || this.lv_InputData.zzPdinsRCCId === '') ? ' ' : this.lv_InputData.zzPdinsRCCId // 상품보험관계구성코드
      if ( ptclrPrdTId === '29' && pdinsRCCId === '60') {
        this.lv_dpstrDisabled = true
      }
    }

    // 초기정보 조회
    let pId = this.lv_InputData.entplId
    this.jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: pId}}, vuchId: ' ', dpstrInfo: {chnlCustId: ''}})
    PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)

    // 초회납입방법 신용카드납 on/off 체크 (ASR240400333_24.05 장한나)
    this.fn_CardYN ()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
    return
  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {
     // backButton event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_DevicBackBtnHandler)

  },//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {},//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {},//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    
  /*---------------------------------------------------------------------------------
  * UI 이벤트 핸들러 영역
  ---------------------------------------------------------------------------------*/
    open() { this.$refs.nsbottomsheet.open();  },
    close() { this.$refs.nsbottomsheet.close(); },
    /******************************************************************************
    * Function명  : fn_DevicBackBtnHandler
    * 설명        : 디바이스 Back Button 핸들러
    ******************************************************************************/
    fn_DevicBackBtnHandler() {
      if(PSCommUtil.fn_IsActiveModal()) return
      this.fn_HeaderBackBtnHandler()
    },

    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : 샘플 서비스 호출 함수
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: '모바일청약 진행을 종료하고 청약서발행현황으로 이동하시겠습니까?',
         title_pos_btn: "확인",
         title_neg_btn: "취소"
       },
       listeners: {
         onPopupConfirm: () => {
           //컨설턴트 초기화
           this.getStore('cmStore').dispatch('GSS_EPLY_INFO', {})
           this.$bottomModal.close(this.lv_AlertPop);
           this.$MenuManager.fn_RouterPushSrnId('MSPPS220M')
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
         }
        }
      })
    },// fn_HeaderBackBtnHandler

    /******************************************************************************
    * Function명 : fn_showTSSCM122D
    * 설명       : 고객개인정보 수정 팝업 열기
    ******************************************************************************/
    fn_showTSSCM122D (targetChnlCustId) {
      let lv_Vm = this
      let pCnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }

      this.rpblPopup = this.$bottomModal.open(MSPCM300P, {
        properties: {
              callerId : this.$options.screenId, 
              viewId : 'MSPCM301D',
              pChnlCustId : targetChnlCustId,
              pCnsltNo : pCnsltNo,
        },
        listeners: { 
          // 대상팝업 onPopupClose $emit 이벤트명 
          onPopupClose : (rtnData) => {    
            this.fn_hideContPtcpInfo()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기        

          },
          closePopup : (rtnData) => {            
            this.fn_hideContPtcpInfo()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기        

          },
          onPopupConfirm: (rtnData) => {            
            this.fn_hideContPtcpInfo()
            this.$bottomModal.close(this.rpblPopup) // 모달 닫기        

          },
        }
      },
      {
        properties: {
          noHeader: true
        }
      })
    },

    /*********************************************************
     * @function fn_OpenMSPCM304P
     * @name     세대조정_팝업_호출
     * @notice   세대조정 팝업 Open
     *           팝업 닫힌후, 고객정보 재조회
    *********************************************************/
    fn_OpenMSPCM304P () {
      const lv_Vm = this
      let pCnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }

      let popObj = this.$bottomModal.open(MSPCM304P, {
        properties: {
          pCustInfo: {
            chnlCustId: this.lv_contr.chnlCustId,
            cnsltNo: pCnsltNo,
          },
          pCallViewCd: 'PS'
        },
        listeners: {
          onPopupConfirm: () => {
            this.fn_hideContPtcpInfo()
            this.$bottomModal.close(popObj)
          },
          onPopupClose: () => {
            this.$bottomModal.close(popObj)
            this.fn_hideContPtcpInfo()
          }
        }
      });

    },

    /*********************************************************
     * Function명: fn_hideContPtcpInfo
     * 설명: 세대조정, 고객정보 수정 창을 닫는다.
     *********************************************************/
    fn_hideContPtcpInfo () {
      // this.showPopupTSSCM121M = false
      // this.isTSSCM122D = false

      // 예금주, 친권1, 친권2 선택초기화
      this.lv_dpstrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      this.dPstrContrFmrtSel = {key: '', label: ''}

      this.lv_Prtr1Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      this.prtr1ContrFmrtSel = {key: '', label: ''}

      this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      this.prtr2ContrFmrtSel = {key: '', label: ''}
      this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화

      // 공동친권 초기화
      this.isCoprPrtrMuagr = false

      // 지정대리청구인 디폴트 세팅 수정 (ASR240900796_김문준_241008 반영)
      this.fn_Init_ApcAsgn()

      // 지정대리청구인 초기화
      this.isApcAsgnView = false
      this.isApcAsgnView2 = false
      this.init_apcInfo('A1')
      this.init_apcInfo('A2')
      // this.lv_asgnProxyClamInfoSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      // this.asgnProxyClamContrRltnScCdSel = {key: '', label: ''}

      // 계약정보 초기조회
      let pId = this.lv_InputData.entplId
      this.jsonString = JSON.stringify({tmodel: {searchCriteria: {zaPlanId: pId}}, vuchId: ' ', dpstrInfo: {chnlCustId: ''}})
      PSElstUtil.fn_EltrnSignBaseInfo('txTSSPS13S2', this.jsonString, this.fn_ServiceDataResultHandler)
    },

    /******************************************************************************
    * Function명 : fn_moveMobslList
    * 설명       : 이전화면 이동
    ******************************************************************************/
    fn_moveMobslList () {
      // 모바일청약 Tab으로 이동
      let pSrnId = 'MSPPS230M' //TSSPS210M
      let pParams = {}
      pParams.tabMenu = pSrnId
      pParams.tabIdx = 1 // 중단건 목록조회 Tab
      pParams.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId 
      
      let t_RouterParam = {name: pSrnId, params: pParams}
      window.vue.getInstance('router').push(t_RouterParam)
    },
    
   /*********************************************************
    * Function명: fn_Init_ApcAsgn
    * 설명       : 지정대리청구인 > 초기세팅 / (2021.05.31_PJO)
    *********************************************************/
    fn_Init_ApcAsgn () {
      // this.isAsgnProxyClamPsb = this.lv_InputData.isAsgnProxyClamPsb
      // this.apcAsgnYn = 'Y'
      // 보험유형관리코드RD
      // N01 : 치매보험 - 2인까지 지정가능
      // 그외 : 1인 지정가능
      // this.zzInsrTypMgId = 'N01' // 테스트용.삭제할것
      console.log('보험유형관리코드RD [' + this.zzInsrTypMgId + ']')
      if ( this.zzInsrTypMgId === 'N01' ) {
        // N01 : 치매보험 - 2인까지 지정가능
        this.apcInfoMsg = '은 피보험자의 「가족관계등록부상 또는 주민등록상의 배우자」 또는 「3촌이내 친족」만 지정가능'
        this.apcInfoMsg2 = '간편지정대리청구인으로 지정 시 계약자 外 지정대리청구인 성명 직접입력 바랍니다.(피보험자의 가족관계등록부상 또는 주민등록상의 배우자/아들/딸 만 지정가능)' // ASR240200999_간편지대청 도입(김문준/240423)
        this.apcPrsonAplScList = [{key: '01', label: '1인지정'}, {key: '02', label: '2인지정'}, {key: 'M1', label: '미지정(계약자요청)'}, {key: 'M2', label: '지정불가(약관상 자격 갖춘 자 없음)'}]
        /* [ASR211000216] 모바일청약 지정대리청구인 사전정보 화면 로직 수정 - 초기화 오류 수정 */
        this.apcPrsonAplScSel = {key: '', label: ''} // 기본값 ( 선택시.여기세팅 )
      } else {
        // 그외 : 1인 지정가능
        this.apcInfoMsg = '은 가족관계증명서 또는 주민등록등본을 통하여 피보험자와 동거하거나 생계를 같이 하고 있는 배우자 또는 3촌이내 친족이 맞는지 확인 필요'
        this.apcPrsonAplScList = [{key: '01', label: '1인지정'}, {key: 'M1', label: '미지정(계약자요청)'}, {key: 'M2', label: '지정불가(약관상 자격 갖춘 자 없음)'}]
        this.apcPrsonAplScSel = {key: 'M1', label: '미지정(계약자요청)'} // 기본값세팅 미지정
      }
      /* [ASR211000216] 모바일청약 지정대리청구인 사전정보 화면 로직 수정 - 초기화 오류 수정 */
      this.apcPrsonAplScCd = this.apcPrsonAplScSel.key
    },

    /*********************************************************
     * Function명: fn_ApcPrsonAplScSel_OnClick
     * 설명       : 지정대리청구인 > 선택 / (2021.05.31_PJO)
     *********************************************************/
    fn_ApcPrsonAplScSel_OnClick (param) {
      let vKey = ''
      if ( param ) {
        vKey = param.key
        // 선택값 초기화
        this.init_apcInfo('A1')
        this.init_apcInfo('A2')
        this.fn_SetApc1('') // 240402
        this.fn_SetApc2('')
        this.apcPrsonAplScCd = vKey
        if (vKey === '01') { // 01 1인지정
          this.isApcAsgnView = true
          this.isApcAsgnView2 = false
          this.apcLblTxt1 = '지정대리청구인'
        } else if (vKey === '02') { // 02 2인지정
          this.isApcAsgnView = true
          this.isApcAsgnView2 = true
          this.apcLblTxt1 = '지정대리청구인1(대표)'
          this.apcLblTxt2 = '지정대리청구인2'
        } else { // M1 미지정(계약자요청),M2 지정불가(약관상 자격 갖춘 자 없음)
          this.isApcAsgnView = false
          this.isApcAsgnView2 = false
          // ASR211000708_지정대리청구 로직 수정 및 신규 팝업 반영_사랑온 가입설계_20211109_youngki78.lee
          if ( this.zzInsrTypMgId === 'N01' ) { // 실제 가입설계한 주보험+특약의 보험유형관리코드RD = 'N01' 이면 지정대리인 2인 지정가능, 간편지대청가능
            if (vKey === 'M1' || vKey === 'M2') { // M1 미지정, M2 지정불가
              this.$refs.refApcAlertFlag.open() // ASR241100645_간편 지정대리청구인 넛지 (241203_PJO)
              // asis 주석처리
              // let showAlert = '지정대리청구인을 지정하지 않은 경우' +
              //                 '<br>보험금 청구시 고객 불편이 발생될 수 있습니다.' +
              //                 '<br>지정여부를 다시 확인하여 주시기 바랍니다.'
              // this.fn_ConfirmMsg(showAlert)
            }
          }
        }
      }
    }, // fn_ApcPrsonAplScSel_OnClick

    /******************************************************************************
    * Function명 : init_apcInfo
    * 설명       : 지정대리청구인 선택값 초기화  / (2021.05.31_PJO)
    ******************************************************************************/
    init_apcInfo (param) {
      if (param === 'A1') {
        this.lv_asgnProxyClamInfoSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.asgnProxyClamContrRltnScCdSel = {key: '', label: ''}
        // ASR240200999_간편지대청 도입(김문준/240423)
        this.apcCustNm_simple1 = '' // 간편지대청_고객명
        this.apclamInfoSel_simple1= {key: '', label: ''} // 간편지대청_피보험자와의 관계
      } else if (param === 'A2') {
        this.lv_asgnProxyClamInfoSel2 = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.asgnProxyClamContrRltnScCdSel2 = {key: '', label: ''}
        // ASR240200999_간편지대청 도입(김문준/240423)
        this.apcCustNm_simple2 = '' // 간편지대청_고객명
        this.apclamInfoSel_simple2= {key: '', label: ''} // 간편지대청_피보험자와의 관계
      }
    },

    /*********************************************************
     * Function명: fn_SetApc1
     * 설명: 지정대리청구인1 변경시 확인
     *********************************************************/
    fn_SetApc1 (param) {
      // ASR240200999_간편지대청 도입(김문준/240423)
      console.log('==> fn_SetApc1 [' + param.key + ']')
      if (param.key === undefined || param.key ==='' ) { // 20240402  ( 선택하세요 클릭시)
        this.isApcAsgnView_simple1 = false
        this.isApcAsgnView_default1 = true // 고객카드에 가족없는경우 세대조정 버튼 영역 보이기위해 true 설정함
        this.simpAsgnClamAgntCd01 = ''	// 지정대리인1_입력유형
        this.lv_asgnProxyClamInfoSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.apcCustNm_simple1 = ''
        this.apclamInfoSel_simple1.key = ''
      } else if (param.key === 'SIMPLE' ) {
        // ⑥ 선택하세요 → "1인지정"으로 디폴트(간편지정대리청구인 활성화와 동일 요건(치매급부 있는 계약에서만 적용)
        // ⑦ 지정대리청구인1 "간편지대청" 으로 디폴트
        this.isApcAsgnView_simple1 = true
        this.isApcAsgnView_default1 = false
        this.simpAsgnClamAgntCd01 = 'B'	// 지정대리인1_입력유형
        this.lv_asgnProxyClamInfoSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: 'SIMPLE', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        // this.apclamInfoSel = {key: '', label: ''} // 일반지대청_피보험자와의 관계
        this.apcCustNm_simple1 = '' // 20240402 
        this.apclamInfoSel_simple1.key = ''
      } else { // if (!this.$bizUtil.isEmpty(this.apcCustNm_simple1)) {
        this.isApcAsgnView_simple1 = false
        this.isApcAsgnView_default1 = true
        this.simpAsgnClamAgntCd01 = 'A'	// 지정대리인1_입력유형
        this.lv_asgnProxyClamInfoSel = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}
        this.fn_chkApc(param)
      }
    },
    /*********************************************************
     * Function명:fn_SetApc1 <-  fn_chkApc2
     * 설명: 지정대리청구인 변경시 확인
     *********************************************************/
    fn_SetApc2 (param) {
      // ASR240200999_간편지대청 도입(김문준/240423)
      console.log('==> fn_SetApc2 [' + param.key + ']')
      if (param.key === undefined || param.key ==='' ) { // 20240402  ( 선택하세요 클릭시)
        this.isApcAsgnView_simple2 = false
        this.isApcAsgnView_default2 = true // 고객카드에 가족없는경우 세대조정 버튼 영역 보이기위해 true 설정함
        this.simpAsgnClamAgntCd02 = ''	// 지정대리인1_입력유형
        this.lv_asgnProxyClamInfoSel2 = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}
        this.apcCustNm_simple2 = ''
        this.apclamInfoSel_simple2.key = ''
      } else if (param.key === 'SIMPLE' ) {
        // this.fn_InitApcInfo('A2') // 기존 선택값 초기화
        this.isApcAsgnView_simple2 = true
        this.isApcAsgnView_default2 = false
        this.simpAsgnClamAgntCd02 = 'B' // 지정대리인2_입력유형
        this.lv_asgnProxyClamInfoSel2 = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}
        this.apcCustNm_simple2 = '' // 20240402 
        this.apclamInfoSel_simple2.key = ''
      } else {
        this.isApcAsgnView_simple2 = false
        this.isApcAsgnView_default2 = true
        this.simpAsgnClamAgntCd02 = 'A' // 지정대리인2_입력유형
        this.lv_asgnProxyClamInfoSel2 = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}
        this.fn_chkApc(param)
      }
    },

    /*********************************************************
     * Function명: fn_chkApc
     * 설명: 지정대리청구인 변경시 확인
     *********************************************************/
    fn_chkApc (param) {
      
      if (param.isMcnstYn === 'N') {
        // 필수컨설팅동의여부 가 N인 경우, 예금주 지정 불가
        //this.showAsgnProxyClamConfirm = true
        this.fn_BottomConfirm('onApcMcnstConfirmMove', '필수컨설팅 동의가 없는 고객은 지정대리청구인 지정이 불가능합니다. 고객등록동의 화면으로 이동하시겠습니까?')
      }
      // (2021.05.31_PJO)
      if (this.isApcAsgnView2) {
        if ( this.lv_asgnProxyClamInfoSel.chnlCustId === this.lv_asgnProxyClamInfoSel2.chnlCustId ) {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인 동일인 선택 불가')
          this.init_apcInfo('A2')
        }
      }
    },

    /*********************************************************
     * Function명: fn_chkDpstr
     * 설명: 예금주 변경시 확인
     *********************************************************/
    fn_chkDpstr (param) {
      this.lv_dpstrSel = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}

      // 예금주가 미성년자인 경우 예금주 지정 불가
      if (!this.$bizUtil.compareCurrentAge(param.knbEncr.substr(0, 6), this.standardAge19)) {
        this.getStore('confirm').dispatch('ADD', '미성년자는 예금주 지정이 불가합니다.')

        this.lv_dpstrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.dPstrContrFmrtSel = {key: '', label: ''}
        this.fn_urlTrnsShowCheck() // URL전송 여부표시
        return
      } else if (param.isMcnstYn === 'N') {
      // 필수컨설팅동의여부 가 N인 경우, 예금주 지정 불가
        //this.showDpstrMcnstConfirm = true
        this.fn_BottomConfirm('onMcnstConfirmMoveN','필수컨설팅 동의가 없는 고객은'
                                                  + '<br>예금주 지정이 불가능합니다.'
                                                  + '<br>\고객등록동의 화면으로 이동하시겠습니까?')

        this.fn_urlTrnsShowCheck() // URL전송 여부표시
        return
      }

      // 계약자와의 관계 설정
      if (param.chnlCustId === this.lv_contr.chnlCustId) {
        this.dPstrContrFmrtSel = {key: '01', label: '본인'}
      } else {
        //this.isDpstrChange = true // 계약자, 예금주 상이 confirm 표시
        let msg = ''
        if ( this.CARD_YN_CHK === 'Y' ) { // 신용카드 문구추가 (ASR240400333_24.05 장한나)
          msg = '예금주 상이건의 경우,'
              + '<br>초회납입방법 가상계좌, 카카오페이, 신용카드납입은 처리 불가합니다.'
              + '<br><br>진행하시겠습니까?'
        } else {
          msg = '예금주 상이건의 경우,'
              + '<br>초회납입방법 가상계좌 및 카카오페이납입은 처리 불가합니다.'
              + '<br><br>진행하시겠습니까?'
        }
        this.fn_BottomConfirm('onMcnstConfirmMove',msg)
        
        if (this.dPstrContrFmrtSel.key === '01') {
          // 계약자 != 예금주인경우 공백으로 수정
          this.dPstrContrFmrtSel = {key: '', label: ''}
        }
      }

      this.fn_urlTrnsShowCheck() // URL전송 여부표시
    },
    /*********************************************************
     * Function명: fn_chkDPstrContrFmrt
     * 설명: 예금주 - 계약자와의 관계 변경시 확인
     *********************************************************/
    fn_chkDPstrContrFmrt (param) {
      // 1. 01:본인인 경우 본인으로 설정
      if (param.key === '01') {
        this.householdMemberList.forEach((item) => {
          if (item.chnlCustId === this.lv_contr.chnlCustId) {
            if (!this.$bizUtil.compareCurrentAge(item.knbEncr.substr(0, 6), this.standardAge19)) {
              this.getStore('confirm').dispatch('ADD', '미성년자는 예금주 지정이 불가합니다.')

              this.lv_dpstrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
              this.dPstrContrFmrtSel = {key: '', label: ''}
            } else {
              this.lv_dpstrSel = item
            }
            this.fn_urlTrnsShowCheck() // URL전송 여부표시
          }
        })
      } else {
        // 2. 본인이 아닌 경우, 예금주 란은 초기화
        if ( this.lv_dpstrSel.chnlCustId === this.lv_contr.chnlCustId ) {
          this.lv_dpstrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        }
      }
    },

        /******************************************************************************
    * Function명 : fn_urlTrnsShowCheck
    * 설명       : 청약 URL전송여부 표시
    ******************************************************************************/
    fn_urlTrnsShowCheck () {
      this.urlTrnsContrShow = false // 계약자 청약URL 표시
      this.urlTrnsInsuredShow = false // 피보험자 청약URL 표시
      this.urlTrnsDpstrShow = false // 예금주 청약URL 표시
      this.urlTrnsPrtr1Show = false // 친권1 청약URL 표시
      this.urlTrnsPrtr2Show = false // 친권2 청약URL 표시

      // (ASR210400763_2021.04.23 PJO)
      // 꿈나무어린이 보험인경우  - 대상상품 : 꿈나무 어린이보험 限(상품코드 앞 여섯자리가 'LP0630'인 경우)
      // 요청사항 : 계약관계자(계약자 또는 피보험자)가  만19세 미만인 경우  친권자1 이 대리 서명 진행
      // -> 청약서URL전송버튼 안보내기
      // console.log('상품코드:[' + this.planInfoData[0].prcdId + '],standardAge15:' + this.standardAge15)
      // if (!this.$bizUtil.isEmpty(this.planInfoData[0].prcdId) && this.planInfoData[0].prcdId.length >= 6) {
      //   if (this.planInfoData[0].prcdId.substr(0, 6) === 'LP0630') {
      //     this.standardAge15 = this.standardAge19
      //   }
      // }
      //
      // (ASR220400794_22.05.10 PJO)_START
      // 변경
      // 계약자/피보험자가 만14세 미만이면 or
      // (상품코드 앞6자리 = LP0665 OR 상품코드 앞6자리 = LP0630) and 계약자/피보험자 연령 : 만19세 미만인 경우  친권인이 대신 작성
      let _prcdId = (!this.$bizUtil.isEmpty(this.planInfoData[0].prcdId) && this.planInfoData[0].prcdId.length >= 6) ? this.planInfoData[0].prcdId.substring(0, 6) : ' ' // 상품코드
      if (_prcdId === 'LP0665' || _prcdId === 'LP0630' || _prcdId === 'LP0715') { // LP0715 꿈모아 - 추가반영 (ASR230900952_23.10.10 PJO)
        this.standardAge15 = this.standardAge19 // 꿈나무 계/피 알림톡발송대상여부 19세로 세팅
      }
      console.log('상품코드::[' + this.planInfoData[0].prcdId + '],standardAge15:' + this.standardAge15)
      // (ASR220400794_22.05.10 PJO)_END

      // 계약자 : 15세 이상인 경우 발송
      if (this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge15) === true) {
        this.urlTrnsContrShow = true
      }

      // 피보험자 : 계약자 != 피보험자 && 15세 이상인 경우 발송
      if (this.contrInsrdSameYn !== 'Y' &&
          this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge15) === true) {
        this.urlTrnsInsuredShow = true
      }

      // 종피보험자 : 계약자 != 종피보험자 && 피보험자 != 종피보험자 15세 이상인 경우 발송
      if (this.showAddInsuredRow &&
          this.lv_contr.chnlCustId !== this.lv_addInsured.chnlCustId &&
          this.lv_insured.chnlCustId !== this.lv_addInsured.chnlCustId &&
          this.$bizUtil.compareCurrentAge(this.lv_addInsured.custRrnId.substr(0, 6), this.standardAge15) === true) {
        this.urlTrnsAddInsuredShow = true
      }

      // 예금주
      // 계약자 !== 예금주 && 피보험자 !== 예금주
      // 종피보험자 N || (종피보험자 Y && 예금주 !== 종피보험자)
      if (!this.$bizUtil.isEmpty(this.lv_dpstrSel.chnlCustId) &&
          this.lv_contr.chnlCustId !== this.lv_dpstrSel.chnlCustId &&
          this.lv_insured.chnlCustId !== this.lv_dpstrSel.chnlCustId &&
          (this.showAddInsuredRow === false ||
           (this.showAddInsuredRow === true && this.lv_addInsured.chnlCustId !== this.lv_dpstrSel.chnlCustId)
          )) {
        this.urlTrnsDpstrShow = true
      }

      // 친권1
      // 계약자 !== 친권1 && 피보험자 !== 친권1
      // 예금주 !== 친권1
      // 종피보험자 N || (종피보험자 Y && 종피보험자 !== 친권1)
      if (this.lv_Prtr1Sel.chnlCustId !== '' &&
          this.lv_contr.chnlCustId !== this.lv_Prtr1Sel.chnlCustId &&
          this.lv_insured.chnlCustId !== this.lv_Prtr1Sel.chnlCustId &&
          this.lv_dpstrSel.chnlCustId !== this.lv_Prtr1Sel.chnlCustId &&
          (this.showAddInsuredRow === false ||
           (this.showAddInsuredRow === true && this.lv_addInsured.chnlCustId !== this.lv_Prtr1Sel.chnlCustId)
          )) {
        this.urlTrnsPrtr1Show = true
      }

      // 친권2
      // 계약자 !== 친권2 && 피보험자 !== 친권2
      // 예금주 !== 친권2
      // 종피보험자 N || (종피보험자 Y && 종피보험자 !== 친권2)
      // 1인친권자 X
      // 의견협치공동친권 X
      if (this.lv_Prtr2Sel.chnlCustId !== '' &&
          this.lv_contr.chnlCustId !== this.lv_Prtr2Sel.chnlCustId &&
          this.lv_insured.chnlCustId !== this.lv_Prtr2Sel.chnlCustId &&
          this.lv_dpstrSel.chnlCustId !== this.lv_Prtr2Sel.chnlCustId &&
          (this.showAddInsuredRow === false ||
           (this.showAddInsuredRow === true && this.lv_addInsured.chnlCustId !== this.lv_Prtr2Sel.chnlCustId)
          ) &&
          // this.prtr2ContrFmrtSel.key !== '90' &&
          this.singlePrtrCheckbox === false &&
          !this.isCoprPrtrMuagr) {
        this.urlTrnsPrtr2Show = true
      }
    },

    /*********************************************************
     * Function명: fn_chkPrtr1
     * 설명: 친권 변경시 확인
     *********************************************************/
    fn_chkPrtr1 (param) {
      this.fn_chkPrtr (param, '1')
    },
    /*********************************************************
     * Function명: fn_chkPrtr2
     * 설명: 친권 변경시 확인
     *********************************************************/
    fn_chkPrtr2 (param) {
      this.fn_chkPrtr (param, '2')
    },

    /*********************************************************
     * Function명: fn_chkPrtr
     * 설명: 친권 변경시 확인
     *********************************************************/
    fn_chkPrtr (param, prtrNo) {
      if(prtrNo === '1'){
        // 친권1
        this.lv_Prtr1Sel = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}  
      } else {
        // 친권2
        this.lv_Prtr2Sel = {celno: param.celno, chnlCustId: param.chnlCustId, isMcnstYn: param.isMcnstYn, key: param.key, knbEncr: param.knbEncr, knbEncr1: param.knbEncr1, knbEncr2: param.knbEncr2, label: param.label, name: param.name, style: param.style}  
      }

      let emptyPrtrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
      let emptyContrFmrtSel = {key: '', label: ''}

      // 1. 필수컨설팅동의여부 가 N인경우
      if (param.isMcnstYn === 'N') {
        this.prtrMcnstConfirmNo = prtrNo
        //this.showPrtrMcnstConfirm = true
        this.fn_BottomConfirm('onPrtrMcnstConfirmMove','필수컨설팅 동의가 없는 고객은 친권자 지정이 불가능합니다. 고객등록동의 화면으로 이동하시겠습니까?')
        return
      } else if (!this.$bizUtil.compareCurrentAge(param.knbEncr.substr(0, 6), this.standardAge19)) {
        // 2. 친권1이 미성년자인 경우
        this.getStore('confirm').dispatch('ADD', '미성년자는 친권자 지정이 불가합니다.')

        if (prtrNo === '1') {
          this.lv_Prtr1Sel = emptyPrtrSel
          this.prtr1ContrFmrtSel = emptyContrFmrtSel
        } else if (prtrNo === '2') {
          this.lv_Prtr2Sel = emptyPrtrSel
          this.prtr2ContrFmrtSel = emptyContrFmrtSel
          this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
        }
        return
      }

      if (prtrNo === '1') {
        if (param.chnlCustId === this.lv_Prtr2Sel.chnlCustId) {
          this.getStore('confirm').dispatch('ADD', '친권1은 친권2와 동일인 지정이 불가능 합니다.')
          this.lv_Prtr1Sel = emptyPrtrSel
          this.prtr1ContrFmrtSel = emptyContrFmrtSel
          return
        }
      } else if (prtrNo === '2') { 
        if (param.chnlCustId === this.lv_Prtr1Sel.chnlCustId) {
          this.getStore('confirm').dispatch('ADD', '친권2는 친권1과 동일인 지정이 불가능 합니다.')
          this.lv_Prtr2Sel = emptyPrtrSel
          this.prtr2ContrFmrtSel = emptyContrFmrtSel
          return
        }
      }

      // URL전송 여부표시
      this.fn_urlTrnsShowCheck()
    },

    /******************************************************************************
    * Function명 : fn_chkPrtr1ContrFmrt
    * 설명       : 친권 지정확인
    ******************************************************************************/
    fn_chkPrtrContrFmrt (prtrNo) {
      if (prtrNo === '1') {
        if ( this.prtr1ContrFmrtSel.key === this.prtr2ContrFmrtSel.key) {
          // 친권2와의 관계 검증
          this.prtr1ContrFmrtSel = {key: '', label: ''}
          this.getStore('confirm').dispatch('ADD', '친권2와 동일한 관계가 지정되었습니다.')
        } else if (this.lv_Prtr1Sel.chnlCustId === '') {
          // 친권1 지정여부 확인
          this.prtr1ContrFmrtSel = {key: '', label: ''}
          this.getStore('confirm').dispatch('ADD', '친권1이 지정되지 않았습니다.')
        } else {
          // 친권인지정 confirm : 미성년자 계약으로 법적대리인으로 친권인이 지정되었음을 확인하였습니까?
          this.isPrtrShowId = prtrNo
          //this.isPrtrShow = true
          this.fn_BottomConfirm('prtrShowConfirmMove',this.prtrShowMessage)
        }
      } else if (prtrNo === '2') {
        if ( this.prtr1ContrFmrtSel.key === this.prtr2ContrFmrtSel.key) {
          // 친권1과 관계 검증
          this.prtr2ContrFmrtSel = {key: '', label: ''}
          this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
          this.getStore('confirm').dispatch('ADD', '친권1과 동일한 관계가 지정되었습니다.')
        // } else if (this.prtr2ContrFmrtSel.key === '90') {
        } else if (this.singlePrtrCheckbox === true) {
          // 공동친권을 선택한 경우, 1인친권자를 선택할수 없음
          if (this.isCoprPrtrMuagr) {
            this.getStore('confirm').dispatch('ADD', '공동친권이 선택되어, 1인친권자를 선택할 수 없습니다.')
            this.prtr2ContrFmrtSel = {key: '', label: ''}
            this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
            return
          }
          // 90: 1인친권자
          // confirm 문구 확인
          //this.isOnePersonPrtrShow = true
          this.fn_BottomConfirm('onePersonPrtrShow',this.onePersonPrtrShowMessage)
          // 친권2 성함 초기화
          // this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
          // this.singlePrtrCheckbox = true
          // this.fn_singlePrtrCheckboxOnBoard()
        } else if (this.lv_Prtr2Sel.chnlCustId === '') {
          // 친권2 지정여부 확인
          this.prtr2ContrFmrtSel = {key: '', label: ''}
          this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
          this.getStore('confirm').dispatch('ADD', '친권2가 지정되지 않았습니다.')
        } else {
          // 친권인지정 confirm : 미성년자 계약으로 법적대리인으로 친권인이 지정되었음을 확인하였습니까?
          this.isPrtrShowId = prtrNo
          //this.isPrtrShow = true
          this.fn_BottomConfirm('prtrShowConfirmMove',this.prtrShowMessage)
        }
      }
    },

    /******************************************************************************
    * Function명 : fn_singlePrtrCheckboxOnClick
    * 설명       : 1인친권자 체크박스 클릭
    ******************************************************************************/
    fn_singlePrtrCheckboxOnClick () {
      if (this.singlePrtrCheckbox) {
        // confirm 문구 확인
        //this.isOnePersonPrtrShow = true
        this.fn_BottomConfirm('onePersonPrtrShow',this.onePersonPrtrShowMessage)
      } else {
        this.prtr2ContrFmrtSel = {key: '', label: ''}
        this.showPrtr2Row = true
        this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.componentKey += 1
      }
    },

  /******************************************************************************
   * Function명 : fn_BottomConfirmY
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_BottomConfirmY (contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         single: true
       },
       listeners: {
         onPopupConfirm: () => {
          this.$bottomModal.close(this.lv_AlertPop);
          this.fn_moveMobslList()
         }
       }
     })
   },
  /******************************************************************************
   * Function명 : fn_BottomConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_BottomConfirm (conName, contents) {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: '알림',
         content: contents,
         title_pos_btn: "예",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           
           switch (conName) {
            case 'onApcMcnstConfirmMove': 
              //지정대리청구인 > 필수컨설팅 동의 N > 지정대리청구인 이동 Y
              if (this.lv_asgnProxyClamInfoSel.chnlCustId.trim() !== '') {
                  // this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.lv_asgnProxyClamInfoSel.chnlCustId}})
                  this.$router.push({name: 'MSPCM050M', params: {custNm: this.lv_asgnProxyClamInfoSel.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData, infoPrcusCnsntTypCd:'17'}})
              } else {
                this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
              }
              break
            case 'onPrtrMcnstConfirmMove': 
              //친권자 필수컨설팅 동의가 없는 경우. 친권자 > 필수컨설팅 동의 N > 고객등록동의 화면으로 이동 Y
              if (this.prtrMcnstConfirmNo === '1') {
                if (this.lv_Prtr1Sel.chnlCustId.trim() !== '') {
                  // this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.lv_Prtr1Sel.chnlCustId}})
                  this.$router.push({name: 'MSPCM050M', params: {custNm: this.lv_Prtr1Sel.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData, infoPrcusCnsntTypCd:'17'}})
                } else {
                  this.getStore('confirm').dispatch('ADD', '친권1이 선택되지 않았습니다.')
                }
              } else if (this.prtrMcnstConfirmNo === '2') {
                if (this.lv_Prtr2Sel.chnlCustId.trim() !== '') {
                  // this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.lv_Prtr2Sel.chnlCustId}})
                  this.$router.push({name: 'MSPCM050M', params: {custNm: this.lv_Prtr2Sel.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData, infoPrcusCnsntTypCd:'17'}})
                } else {
                  this.getStore('confirm').dispatch('ADD', '친권2가 선택되지 않았습니다.')
                }
              } else {
                this.getStore('confirm').dispatch('ADD', '친권자가 선택되지 않았습니다.')
              }
              break
            case 'prtrShowConfirmMove': 
              //친권인 지정 확인
              //this.isPrtrShow = false
              this.fn_urlTrnsShowCheck()
              break
            case 'onePersonPrtrShow': 
              //1인친권자
              //this.isOnePersonPrtrShow = false
              this.singlePrtrCheckbox = true
              this.isCoprPrtrMuagr = false // 공동친권 체크박스 초기화
              this.fn_singlePrtrCheckboxOnBoard()
              break
            case 'coprPrtrMuagrShow': 
              //공동친권합의 확인 / 예
              //this.isCoprPrtrMuagrShow = false
              this.urlTrnsPrtr2Show = false // 친권2 청약URL표시여부
              break
            case 'onMcnstConfirmMove': 
              
              break
            case 'onMcnstConfirmMoveN': 
              if (this.lv_dpstrSel.chnlCustId.trim() !== '') {
                // this.$router.push({name: 'MSPCM001M', params: {chnlCustId: this.lv_dpstrSel.chnlCustId}})
                this.$router.push({name: 'MSPCM050M', params: {custNm: this.lv_dpstrSel.name, srnId: this.$options.screenId, aprvType: '01', rtnParam:this.lv_InputData, infoPrcusCnsntTypCd:'17'}})
              } else {
                this.getStore('confirm').dispatch('ADD', '예금주가 선택되지 않았습니다.')
              }
              break
            default:
              break
          } // end switch
          
         },
        onPopupClose: () => {
          this.$bottomModal.close(this.lv_AlertPop);

          switch (conName) {
            case 'onApcMcnstConfirmMove': 
              // 지정대리청구인 항목 초기화
              this.init_apcInfo('A1')
              this.init_apcInfo('A2')
              break
             case 'onPrtrMcnstConfirmMove': 
              //친권자 > 필수컨설팅 동의 N > 고객등록동의 화면으로 이동 N
              if (this.prtrMcnstConfirmNo === '1') {
                this.lv_Prtr1Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
              } else if (this.prtrMcnstConfirmNo === '2') {
                this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
              } else {
                this.getStore('confirm').dispatch('ADD', '친권자가 선택되지 않았습니다.')
              }
              this.fn_urlTrnsShowCheck() // URL전송 여부표시
              break
            case 'prtrShowConfirmMove': 
              //친권인 지정 확인 / 아니오
              //this.isPrtrShow = false
              if (this.isPrtrShowId === '1') {
                // 친권1 관계 초기화
                this.prtr1ContrFmrtSel = {key: '', label: ''}
              } else if (this.isPrtrShowId === '2') {
                // 친권2 관계 초기화
                this.prtr2ContrFmrtSel = {key: '', label: ''}
                this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
              }
              this.fn_urlTrnsShowCheck()
              break
            case 'onePersonPrtrShow': 
              //1인친권자 아니오
              //this.isOnePersonPrtrShow = false
              this.singlePrtrCheckbox = false // 1인친권자 체크박스 초기화
              if (this.prtr2ContrFmrtSel.key === '90') {
                this.prtr2ContrFmrtSel = {key: '', label: ''}
                this.fn_urlTrnsShowCheck()
              }
              break
            case 'coprPrtrMuagrShow': 
              //공동친권합의 확인 / 아니오
              //this.isCoprPrtrMuagrShow = false
              this.isCoprPrtrMuagr = false
              break
            case 'onMcnstConfirmMove': 
              // 예금주를 초기화
              this.lv_dpstrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
              this.dPstrContrFmrtSel = {key: '', label: ''}
              this.fn_urlTrnsShowCheck() // URL전송 여부표시
              break
            case 'onMcnstConfirmMoveN': 
              // 예금주를 초기화
              this.lv_dpstrSel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
              this.dPstrContrFmrtSel = {key: '', label: ''}
              this.fn_urlTrnsShowCheck() // URL전송 여부표시
              break
            default:
              break
          }
        }
       }
     })
   },

    /******************************************************************************
    * Function명 : fn_singlePrtrCheckboxOnBoard
    * 설명       : 1인친권자 체크박스 후처리
    ******************************************************************************/
    fn_singlePrtrCheckboxOnBoard () {
      if (this.singlePrtrCheckbox) {
        // this.prtr2ContrFmrtSel = {key: '90', label: '1인친권자'}
        this.showPrtr2Row = false
        this.lv_Prtr2Sel = {celno: '', chnlCustId: '', isMcnstYn: '', key: '', knbEncr: '', knbEncr1: '', knbEncr2: '', label: '', name: '', style: false}
        this.componentKey += 1
        this.fn_urlTrnsShowCheck()
      }
    },

    /******************************************************************************
    * Function명 : fn_kakaoIapDspchSnProc
    * 설명       : 카카오 알림톡 발송순서 지정
    ******************************************************************************/
    fn_kakaoIapDspchSnProc () {
      this.kakaoIapDspchSnContr = 0 // 계약자
      this.kakaoIapDspchSnInsured = 0 // 피보험자
      this.kakaoIapDspchSnAddInsured = 0 // 종피보험자

      this.kakaoIapDspchSnDpstr = 0 // 예금주

      this.kakaoIapDspchSnPrtr1 = 0 // 친권1
      this.kakaoIapDspchSnPrtr2 = 0 // 친권2

      this.kakaoIapDspchSnFc = 0 // FC

      // 기본순번 부여
      // 알림톡활성화 계약관계자:1, FC:2
      if (this.urlTrnsContrShow) { this.kakaoIapDspchSnContr = 1 }
      if (this.urlTrnsInsuredShow) { this.kakaoIapDspchSnInsured = 1 }
      if (this.urlTrnsAddInsuredShow) { this.kakaoIapDspchSnAddInsured = 1 }
      if (this.urlTrnsDpstrShow) { this.kakaoIapDspchSnDpstr = 1 }
      if (this.urlTrnsPrtr1Show) { this.kakaoIapDspchSnPrtr1 = 1 }
      if (this.urlTrnsPrtr2Show) { this.kakaoIapDspchSnPrtr2 = 1 }
      this.kakaoIapDspchSnFc = 2

      if (!this.showPrtr1Row) {
        // 친권1 Tab X
        if (this.urlTrnsInsuredShow || this.urlTrnsAddInsuredShow || this.urlTrnsDpstrShow) {
          console.log('--순번체크로그-1/' + this.kakaoIapDspchSn)
          // 주피, 종피, 예금주 중에 알림톡 수신자가 1명이라도 있는 경우
          this.kakaoIapDspchSnContr = 2 // 계약자:2
          this.kakaoIapDspchSnFc = 3 // FC:3
        }
      } else {
        let kakaoIapDspchSn = 1 // 알림톡알림발송순번
        console.log('--순번체크로그-2/' + kakaoIapDspchSn)
        if (this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge19) &&
            !this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge19)) {
          // ▶ 계약자 19세미만X , 피보험자가 19세미만O
          // 계약자 19세 이상 , 피보험자 19세 미만
          console.log('--순번체크로그-2-1/' + kakaoIapDspchSn)
          // 친권1이 계약관계자에 속하지 않을 경우 피보험자에게 알림톡 발송
          if (this.lv_Prtr1Sel.chnlCustId !== this.lv_contr.chnlCustId &&
              this.lv_Prtr1Sel.chnlCustId !== this.lv_addInsured.chnlCustId &&
              this.lv_Prtr1Sel.chnlCustId !== this.lv_dpstrSel.chnlCustId) {
            this.kakaoIapDspchSnInsured = 1
            console.log('--순번체크로그-2-1-1/' + kakaoIapDspchSn)
          }

          // 피보험자가 19세 미만 && 친권1이 계약관계자가 아님
          // || 종피알림톡 || 예금주알림톡
          if ((!this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge19) && this.kakaoIapDspchSnInsured === 1) ||
              this.urlTrnsAddInsuredShow || this.urlTrnsDpstrShow) {
            kakaoIapDspchSn = kakaoIapDspchSn + 1
            console.log('--순번체크로그-2-1-2/' + kakaoIapDspchSn)
          }

          // 계약자알림톡
          if (this.urlTrnsContrShow) {
            this.kakaoIapDspchSnContr = kakaoIapDspchSn
            kakaoIapDspchSn = kakaoIapDspchSn + 1
            console.log('--순번체크로그-2-1-3/' + kakaoIapDspchSn)
          }
        } else if (!this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge19) &&
                   this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge19)) {
          // ▶ 계약자 19세미만O, 피보험자가 19세미만X
          console.log('--순번체크로그-3/' + kakaoIapDspchSn)
          // 친권1이 계약관계자에 속하지 않을 경우 계약자에게 알림톡 발송
          if (this.lv_Prtr1Sel.chnlCustId !== this.lv_insured.chnlCustId &&
              this.lv_Prtr1Sel.chnlCustId !== this.lv_addInsured.chnlCustId &&
              this.lv_Prtr1Sel.chnlCustId !== this.lv_dpstrSel.chnlCustId ||
              this.urlTrnsContrShow) {
            kakaoIapDspchSn = kakaoIapDspchSn + 1
            this.kakaoIapDspchSnContr = kakaoIapDspchSn
            console.log('--순번체크로그-3-1/' + kakaoIapDspchSn)
          } else if (this.urlTrnsInsuredShow && this.lv_Prtr1Sel.chnlCustId === this.lv_insured.chnlCustId &&
                    (this.urlTrnsAddInsuredShow || this.urlTrnsDpstrShow)) {
            kakaoIapDspchSn = kakaoIapDspchSn + 1
            this.kakaoIapDspchSnInsured = 2
            console.log('--순번체크로그-3-2/' + kakaoIapDspchSn)
          } else if (this.urlTrnsAddInsuredShow && this.lv_Prtr1Sel.chnlCustId === this.lv_addInsured.chnlCustId &&
                    (this.urlTrnsInsuredShow || this.urlTrnsDpstrShow)) {
            kakaoIapDspchSn = kakaoIapDspchSn + 1
            this.kakaoIapDspchSnAddInsured = 2
            console.log('--순번체크로그-3-3/' + kakaoIapDspchSn)
          } else if (this.urlTrnsDpstrShow && this.lv_Prtr1Sel.chnlCustId === this.lv_dpstrSel.chnlCustId &&
                    (this.urlTrnsInsuredShow || this.urlTrnsAddInsuredShow)) {
            kakaoIapDspchSn = kakaoIapDspchSn + 1
            this.kakaoIapDspchSnDpstr = 2
            console.log('--순번체크로그-3-4/' + kakaoIapDspchSn)
          }

          // 1증가
          kakaoIapDspchSn = kakaoIapDspchSn + 1
        } else if (!this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge19) &&
                   !this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge19)) {
          // ▶ 계약자 19세미만O, 피보험자가 19세미만O
          console.log('--순번체크로그-4/' + kakaoIapDspchSn)
          console.log('▶ 계약자 19세미만O, 피보험자가 19세미만O:this.standardAge15:' + this.standardAge15)
          // 피보험자가 15세 미만O && 계약자 != 피보험자 && 친권1이 계약관계자에 속하지 않은 경우
          if (!this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge15) &&
              this.lv_contr.chnlCustId !== this.lv_insured.chnlCustId &&
              this.lv_Prtr1Sel.chnlCustId !== this.lv_addInsured.chnlCustId &&
              this.lv_Prtr1Sel.chnlCustId !== this.lv_dpstrSel.chnlCustId) {
            // 피보험자에게 알림톡 발송 (발송시 15세미만으로 친권1에게 발송 / PSKakaoIapTalkBIZ.java)
            this.kakaoIapDspchSnInsured = 1
            console.log('--순번체크로그-4-1/' + kakaoIapDspchSn)
          }

          if (this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge15)) {
            // 계약자 15세 미만 X, 알림톡 순번 2
            kakaoIapDspchSn = kakaoIapDspchSn + 1
            this.kakaoIapDspchSnContr = kakaoIapDspchSn
            console.log('--순번체크로그-4-2/' + kakaoIapDspchSn)
          } else {
            console.log('--순번체크로그-4-3/' + kakaoIapDspchSn)
            // 계약자 15세 미만O
            if (this.showAddInsuredRow && this.lv_Prtr1Sel.chnlCustId === this.lv_addInsured.chnlCustId &&
                this.lv_Prtr1Sel.chnlCustId !== this.lv_dpstrSel.chnlCustId) {
              // 친권1이 종피보험자인경우
              // 종피보험자 순번 증가
              kakaoIapDspchSn = kakaoIapDspchSn + 1
              this.kakaoIapDspchSnAddInsured = kakaoIapDspchSn
              console.log('--순번체크로그-4-3-1/' + kakaoIapDspchSn)
            } else if (this.lv_Prtr1Sel.chnlCustId === this.lv_dpstrSel.chnlCustId) {
              // 친권1이 예금주인 경우
              // 예금주 순번 증가
              kakaoIapDspchSn = kakaoIapDspchSn + 1
              this.kakaoIapDspchSnDpstr = kakaoIapDspchSn
              console.log('--순번체크로그-4-3-2/' + kakaoIapDspchSn)
              // 케이스보완 (ASR210400763_2021.04.23 PJO)_START
              if (!this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge15) &&
                  !this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge15) &&
                  this.lv_Prtr1Sel.chnlCustId === this.lv_dpstrSel.chnlCustId) {
                // (계약자 15세미만O & 피보험자 15세미만 O) && 친권자1 == 예금주이면
                // 예금주 순번 1
                kakaoIapDspchSn = 1
                this.kakaoIapDspchSnDpstr = kakaoIapDspchSn
                console.log('--순번체크로그-4-3-2-1/' + kakaoIapDspchSn)
              }
              // 케이스보완 (ASR210400763_2021.04.23 PJO)_END
            } else {
              // 친권1이 계약관계자에 속하지 않은 경우
              // 계약자에게 알림톡 발송, (발송시 15세미만으로 친권1에게 발송 / PSKakaoIapTalkBIZ.java)
              kakaoIapDspchSn = kakaoIapDspchSn + 1
              this.kakaoIapDspchSnContr = kakaoIapDspchSn
              console.log('--순번체크로그-4-3-3/' + kakaoIapDspchSn)
            }
          }
          kakaoIapDspchSn = kakaoIapDspchSn + 1
          console.log('--순번체크로그-4_end/' + kakaoIapDspchSn)
        }
        console.log('--순번체크로그-5/' + kakaoIapDspchSn)
        // 친권1 알림톡 순번 지정
        this.kakaoIapDspchSnPrtr1 = kakaoIapDspchSn

        // 친권2의 알림톡 순번 지정
        // if (this.prtr2ContrFmrtSel.key !== '90' && !this.isCoprPrtrMuagr) {
        if (this.singlePrtrCheckbox === false && !this.isCoprPrtrMuagr) {
          // 90 1인친권인 X, 의견협치공동친권X
          this.kakaoIapDspchSnPrtr2 = kakaoIapDspchSn
          console.log('--순번체크로그-6/' + kakaoIapDspchSn)
        }

        // 계약자, 예금주, 친권1이 동일인인 성인
        // 피보험자는 15세 미만
        // 친권2는 1인친권인이거나, 의견협치공동친권인 경우
        // 1명이 모두 수행하기에 알림톡은 1회만 발송함
        if ( this.kakaoIapDspchSnContr === 1 && // 계약자
             this.kakaoIapDspchSnInsured === 0 && // 피보험자
             this.kakaoIapDspchSnAddInsured === 0 && // 종피보험자
             this.kakaoIapDspchSnDpstr === 0 && // 예금주
             this.kakaoIapDspchSnPrtr1 === 2 && // 친권1
             this.kakaoIapDspchSnPrtr2 === 0 // 친권2
        ) {
          this.kakaoIapDspchSnPrtr1 = 0 // 친권1
          kakaoIapDspchSn = 1
          console.log('--순번체크로그-7/' + kakaoIapDspchSn)
        }

        // FC순번 부여
        kakaoIapDspchSn = kakaoIapDspchSn + 1
        this.kakaoIapDspchSnFc = kakaoIapDspchSn
      } // if (!this.showPrtr1Row) { } else { } 종료
      console.log('### 순번 [' + this.lv_InputData.moDCDocuMngVO.eltrnDoctId + ']')
      console.log('# ' + this.kakaoIapDspchSnContr + ' : 계약자 ')
      console.log('# ' + this.kakaoIapDspchSnInsured + ' : 피보험자 ')
      console.log('# ' + this.kakaoIapDspchSnAddInsured + ' : 종피보험자 ')

      console.log('# ' + this.kakaoIapDspchSnDpstr + ' : 예금주 ')

      console.log('# ' + this.kakaoIapDspchSnPrtr1 + ' : 친권1 ')
      console.log('# ' + this.kakaoIapDspchSnPrtr2 + ' : 친권2 ')
      console.log('# ' + this.kakaoIapDspchSnFc + ' : FC ')

      this.fn_mobpsPblProc()
    },
   
    /******************************************************************************
    * Function명 : fn_chkCoprPrtrMuagr
    * 설명       : 공동친권합의 checkbox
    ******************************************************************************/
    fn_chkCoprPrtrMuagr () {
      if (this.prtr2ContrFmrtSel.key === '') {
        this.isCoprPrtrMuagr = false
        this.getStore('confirm').dispatch('ADD', '친권2의 관계가 설정되지 않았습니다.')
      // } else if (this.prtr2ContrFmrtSel.key === '90') {
      } else if (this.singlePrtrCheckbox === true) {
        // 친권2, 관계:90:1인친권자
        this.isCoprPrtrMuagr = false
        this.getStore('confirm').dispatch('ADD', '1인친권자는 공동친권을 선택할 수 없습니다.')
      } else if (!this.$bizUtil.compareCurrentAge(this.lv_contr.custRrnId.substr(0, 6), this.standardAge19)) {
        // 계약자가 미성년자인 경우 공동친권합이 불가
        this.isCoprPrtrMuagr = false
        this.getStore('confirm').dispatch('ADD', '계약자가 미성년자인경우<br>공동친권을 선택할 수 없습니다.')
      } else if (!this.$bizUtil.compareCurrentAge(this.lv_insured.custRrnId.substr(0, 6), this.standardAge19)) {
        // 수익자 목록 조회
        let lv_bnfrInfoList = this.lv_InputData.bnfrInfoList
        let bnfrInfoCheckState = false
        if (lv_bnfrInfoList !== null && lv_bnfrInfoList !== undefined && lv_bnfrInfoList.length > 0) {
          let itemIdx = 0
          let itemsCnt = lv_bnfrInfoList.length
          for ( itemIdx; itemIdx < itemsCnt; itemIdx++ ) {
            let lv_bnfrInfo = lv_bnfrInfoList[itemIdx]
            if (lv_bnfrInfo.zzbnfrRolCd !== null || lv_bnfrInfo.zzbnfrRolCd !== undefined) {
              // 피보험자가 미성년자(만19세미만)인 경우
              // 1. 상해시 수익자가 피보험자가 아니거나
              // 2. 사망시 수익자가 상속인이 아닐경우
              // 1~2 조건 中 1개이상 해당시 전자서명 블로킹
              if ((lv_bnfrInfo.zzbnfrRolCd === '34' && (lv_bnfrInfo.custNm !== this.lv_insured.custNm || lv_bnfrInfo.knb.substr(0, 6) !== this.lv_insured.custRrnId.substr(0, 6))) ||
                  (lv_bnfrInfo.zzbnfrRolCd === '35' && lv_bnfrInfo.custNm !== '상속인')) {
                bnfrInfoCheckState = true
                break
              }
            }
          }
        } // if (lv_bnfrInfoList !== null && lv_bnfrInfoList !== undefined && lv_bnfrInfoList.length > 0) {

        if (bnfrInfoCheckState) {
          // 피보험자가 미성년자 & 수익자가 지정된 경우
          this.isCoprPrtrMuagr = false
          this.getStore('confirm').dispatch('ADD', '피보험자가 미성년자이며, 수익자가 지정된 경우<br>공동친권을 선택할 수 없습니다.')
        } else if (this.isCoprPrtrMuagr === true) {
          // 피보험자 미성년자 & 수익자 지정이 안된경우 진행
          // 공동친권합의 체크시 확인창 표시
          //this.isCoprPrtrMuagrShow = true
          this.fn_BottomConfirm('coprPrtrMuagrShow',this.coprPrtrMuagrMessage)
        }
      } else if (this.isCoprPrtrMuagr === true) {
        // 공동친권합의 체크시 확인창 표시
        //this.isCoprPrtrMuagrShow = true
        this.fn_BottomConfirm('coprPrtrMuagrShow',this.coprPrtrMuagrMessage)
      } else {
        this.fn_urlTrnsShowCheck()
      }
    },

  /*---------------------------------------------------------------------------------
  * Data Service 핸들러 영역
  ---------------------------------------------------------------------------------*/
    /******************************************************************************
    * Function명 : fn_mobpsPbl
    * 설명       : 모바일청약 발행
    ******************************************************************************/
    fn_mobpsPbl (isSendKakao) {
      
      // 필수값 체크 추가
      let tApcPrson = []
      let tApcPrsons = []
      let tDpstrSel = []
      let tDpstrSels = []
      let tPrtrSel = []
      let tPrtrSels = []

      // 지정대리인 선택 체크
      if(this.isAsgnProxyClamPsb){
        let bApcAplScValid = this.$refs['apcPrsonAplSc'].isValid()
        tApcPrson.push(bApcAplScValid)
        if(!bApcAplScValid){
          this.$refs['contrExp'].isExpand =  true
          tApcPrsons.push('apcPrsonAplSc')
        }

        // 지정대리인1 선택 체크
        if(this.isApcAsgnView){
          // 고객카드선택_관계선택
          if(this.isApcAsgnView_default1) {
            let bApcPrson1RltnValid1 = this.$refs['apcPrson1Rltn1'].isValid()
            tApcPrson.push(bApcPrson1RltnValid1)
            if(!bApcPrson1RltnValid1){
              this.$refs['contrExp'].isExpand =  true
              tApcPrsons.push('apcPrson1Rltn1')
            }
          }
          // 성명입력_관계선택
          else if(this.isApcAsgnView_simple1) {
            let bApcPrson1NmValid = this.$refs['custNmRef1'].isValid()
            tApcPrson.push(bApcPrson1NmValid)
            if(!bApcPrson1NmValid){
              this.$refs['contrExp'].isExpand =  true
              tApcPrsons.push('custNmRef1')
            }

            let bApcPrson1RltnValid2 = this.$refs['apcPrson1Rltn2'].isValid()
            tApcPrson.push(bApcPrson1RltnValid2)
            if(!bApcPrson1RltnValid2){
              this.$refs['contrExp'].isExpand =  true
              tApcPrsons.push('apcPrson1Rltn2')
            }
          }
        }

        // 지정대리인2 선택 체크
        if(this.isApcAsgnView2) {
          // 고객카드선택_관계선택
          if(this.isApcAsgnView_default2) {
            let bApcPrson2RltnValid1 = this.$refs['apcPrson2Rltn1'].isValid()
            tApcPrson.push(bApcPrson2RltnValid1)
            if(!bApcPrson2RltnValid1){
              this.$refs['contrExp'].isExpand =  true
              tApcPrsons.push('apcPrson2Rltn1')
            }
          }
          // 성명입력_관계선택
          else if(this.isApcAsgnView_simple2) {
            let bApcPrson2NmValid = this.$refs['custNmRef2'].isValid()
            tApcPrson.push(bApcPrson2NmValid)
            if(!bApcPrson2NmValid){
              this.$refs['contrExp'].isExpand =  true
              tApcPrsons.push('custNmRef2')
            }

            let bApcPrson2RltnValid2 = this.$refs['apcPrson2Rltn2'].isValid()
            tApcPrson.push(bApcPrson2RltnValid2)
            if(!bApcPrson2RltnValid2){
              this.$refs['contrExp'].isExpand =  true
              tApcPrsons.push('apcPrson2Rltn2')
            }
          }
        }

      }

      // 예금주 성명 체크
      let bDpstrNmValid = this.$refs['dpstrNm'].isValid()
      tDpstrSel.push(bDpstrNmValid)
      if(!bDpstrNmValid){
        this.$refs['dpstrExp'].isExpand =  true
        tDpstrSels.push('dpstrNm')
      }

      // 예금주 관계 체크
      let bDpstrRltnValid = this.$refs['dpstrRltn'].isValid()
      tDpstrSel.push(bDpstrRltnValid)
      if(!bDpstrRltnValid){
        this.$refs['dpstrExp'].isExpand =  true
        tDpstrSels.push('dpstrRltn')
      }
      
      // 친권자1 선택 체크
      if(this.showPrtr1Row){
        // 성명
        let bPrtrNmValid1 = this.$refs['prtr1SelNm'].isValid()
        tPrtrSel.push(bPrtrNmValid1)
        if(!bPrtrNmValid1){
          this.$refs['prtrExp1'].isExpand =  true
          tPrtrSels.push('prtr1SelNm')
        }
        // 관계
        let bPrtrRltnValid1 = this.$refs['prtr1SelRltn'].isValid()
        tPrtrSel.push(bPrtrRltnValid1)
        if(!bPrtrRltnValid1){
          this.$refs['prtrExp1'].isExpand =  true
          tPrtrSels.push('prtr1SelRltn')
        }
      }

      // 친권자2 선택 체크
      if(this.showPrtr2Row && !this.singlePrtrCheckbox){
        // 성명
        let bPrtrNmValid2 = this.$refs['prtr2SelNm'].isValid()
        tPrtrSel.push(bPrtrNmValid2)
        if(!bPrtrNmValid2){
          this.$refs['prtrExp2'].isExpand =  true
          tPrtrSels.push('prtr2SelNm')
        }
        // 관계
        let bPrtrRltnValid2 = this.$refs['prtr2SelRltn'].isValid()
        tPrtrSel.push(bPrtrRltnValid2)
        if(!bPrtrRltnValid2){
          this.$refs['prtrExp2'].isExpand =  true
          tPrtrSels.push('prtr2SelRltn')
        }
      }
      
      
      let allChkVld = []
      allChkVld = allChkVld.concat(tApcPrson)
      allChkVld = allChkVld.concat(tDpstrSel)
      allChkVld = allChkVld.concat(tPrtrSel)

      // 애니메이션을 위한 타임아웃
      setTimeout(() => {
        let isPass = true
        if(isPass && tApcPrsons.length > 0){
          for(let i = 0; i < tApcPrsons.length; i++){
            // 지정청구대리인
            if(tApcPrsons[i] === 'apcPrsonAplSc'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tApcPrsons[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            // 지정청구대리인1
            if(tApcPrsons[i] === 'apcPrson1Rltn1'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tApcPrsons[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tApcPrsons[i] === 'custNmRef1'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tApcPrsons[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tApcPrsons[i] === 'apcPrson1Rltn2'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tApcPrsons[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            // 지정청구대리인2
            if(tApcPrsons[i] === 'apcPrson2Rltn1'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tApcPrsons[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tApcPrsons[i] === 'custNmRef2'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tApcPrsons[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tApcPrsons[i] === 'apcPrson2Rltn2'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tApcPrsons[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
          }
        }

        if(isPass && tDpstrSels.length > 0){
          for(let i = 0; i < tDpstrSels.length; i++){
            if(tDpstrSels[i] === 'dpstrNm'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tDpstrSels[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tDpstrSels[i] === 'dpstrRltn'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tDpstrSels[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
          }
        }

        if(isPass && tPrtrSels.length > 0){
          for(let i = 0; i < tPrtrSels.length; i++){
            if(tPrtrSels[i] === 'prtr1SelNm'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tPrtrSels[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tPrtrSels[i] === 'prtr1SelRltn'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tPrtrSels[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tPrtrSels[i] === 'prtr2SelNm'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tPrtrSels[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
            if(tPrtrSels[i] === 'prtr2SelRltn'){
              document.getElementById('scroll_MSPPS510M').scrollTop = this.$refs[tPrtrSels[i]].$el.offsetTop - 40 // 스크롤이동
              isPass = false
              break
            }
          }
        }
      }, 500)
      

      let allChk = allChkVld.some((item) =>
      {      
      if ( !item )
        {
          return true
        }
      })
      
      if(allChk) return
      
      this.isSendKakao = isSendKakao
      if (this.isAsgnProxyClamPsb) { // 지정대리청구인 지정가능여부(계==피)
        if( !this.fn_ChkApcValidation() ) {
          console.log('---------지대청 체크 false')
          return
        }
      }
      console.log('----------흐르니?')
/* 지정대리청구 validation 체크 -> fn_ChkApcValidation 이동
      if (this.isAsgnProxyClamPsb) { // 지정대리청구인 지정가능여부(계==피)
        if (this.apcPrsonAplScSel.key === '' ) {
          this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인을 선택해 주세요. ') //문구수정(ASR231001107_231107_PJO)
          return
        }

        // 계약자탭  show5 : false 열림 / true  닫힘
        if (this.show1 && this.simpAsgnClamAgntCd01 == 'B' && ( this.apcCustNm_simple1.trim() === '' ||  this.apclamInfoSel_simple1.key.trim() === '')) {
          this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인 정보를 입력하세요!.') //문구수정(ASR231001107_231107_PJO)
          return
        }
      }

      // 1인
      if (this.isApcAsgnView) { // 지정대리청구인 선택여부 (ASR210500282_20210531_PJO)
        if ( this.simpAsgnClamAgntCd01 === '' ) {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
          return false
        } else if ( this.simpAsgnClamAgntCd01 == 'A')  { // 일반입력
          // validation체크만함
          if (this.$bizUtil.isEmpty(this.lv_asgnProxyClamInfoSel.chnlCustId)) {
            this.isMobpsValMessge = '지정대리청구인을 선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
          if (this.$bizUtil.isEmpty(this.asgnProxyClamContrRltnScCdSel.key)) {
            this.isMobpsValMessge = '지정대리청구인의 피보험자와의 관계가<br>선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
        } else if ( this.simpAsgnClamAgntCd01 == 'B')  { // 직접입력
          if( this.apcCustNm_simple1.trim() === '' ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 성명이 입력되지 않았습니다.')
            return false
          }
          if( this.apclamInfoSel_simple1.key.trim() === '' ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 피보험자와의 관계가 선택되지 않았습니다.')
            return false
          }
        }

        // 2인
        if (this.isApcAsgnView2) {
          if (this.$bizUtil.isEmpty(this.lv_asgnProxyClamInfoSel2.chnlCustId)) {
            this.isMobpsValMessge = '지정대리청구인(2)을 선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
          if (this.$bizUtil.isEmpty(this.asgnProxyClamContrRltnScCdSel2.key)) {
            this.isMobpsValMessge = '지정대리청구인(2)의 피보험자와의 관계가<br>선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
        }
      }
*/
      if (this.$bizUtil.isEmpty(this.lv_dpstrSel.chnlCustId)) {
        this.isMobpsValMessge = '예금주를 선택하지 않았습니다.'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
      } else if (this.$bizUtil.isEmpty(this.dPstrContrFmrtSel.key)) {
        this.isMobpsValMessge = '예금주의 계약자관계를 선택하지 않았습니다'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
      } else if (this.showPrtr1Row && this.$bizUtil.isEmpty(this.lv_Prtr1Sel.chnlCustId)) {
        this.isMobpsValMessge = '친권1을 선택하지 않았습니다'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
      } else if (this.showPrtr1Row && this.$bizUtil.isEmpty(this.prtr1ContrFmrtSel.key)) {
        this.isMobpsValMessge = '친권1의 관계를 선택하지 않았습니다'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
      } else if (this.showPrtr2Row && !this.singlePrtrCheckbox && this.$bizUtil.isEmpty(this.prtr2ContrFmrtSel.key)) {
        this.isMobpsValMessge = '친권2의 관계를 선택하지 않았습니다'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
      // } else if ((this.showPrtr2Row || this.singlePrtrCheckbox) && this.prtr2ContrFmrtSel.key !== '90' && this.$bizUtil.isEmpty(this.lv_Prtr2Sel.chnlCustId)) {
      } else if (this.showPrtr2Row && !this.singlePrtrCheckbox && this.$bizUtil.isEmpty(this.lv_Prtr2Sel.chnlCustId)) {
        // 친권2, 관계 : 90:1인친권자
        this.isMobpsValMessge = '친권2를 선택하지 않았습니다'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
      } else {
        this.fn_ServiceData('S1', this.lv_dpstrSel) // 예금주 정보조회
      }
    },

    /******************************************************************************
    * Function명 : fn_ServiceDataResultHandler
    * 설명       : 전자서명 초기 데이타 호출 결과 컨트롤 함수
    ******************************************************************************/
    fn_ServiceDataResultHandler: async function (event) {
      //console.log('★★★★ event.List!!')
      //console.log(event)
      if (event.hoshInfoList) {
        this.hoshInfoList = event.hoshInfoList // 계약자/피보험자 정보
        this.planInfoData = event.planInfoData // 상품정보

        // 계약자 정보 설정 hoshInfoList[0]
        this.lv_contr.custNm = this.hoshInfoList[0].insrdNm // 계약자 이름
        if (this.lv_contr.custNm.length > 4) { // 계약자 이름이 길어질 경우 tooltip표시 여부 확인
          this.lv_contr.showNameTooltip = true
        }
        this.lv_contr.age = this.hoshInfoList[0].age + '세'// 계약자 나이
        this.lv_contr.custRrnId = this.hoshInfoList[0].custRrnId // 계약자 주민번호
        this.lv_contr.custRrnId1 = this.hoshInfoList[0].custRrnId.substring(0, 6) // 계약자 주민번호 앞자리
        this.lv_contr.custRrnId2 = this.hoshInfoList[0].custRrnId.substring(6, 7) + '******' // 계약자 주민번호 뒷 한자리 + 마스킹
        this.lv_contr.custCelno = this.hoshInfoList[0].detail.celno // 계약자 전화번호

        this.lv_contr.chnlCustId = this.hoshInfoList[0].detail.chnlCustId // 계약자 채널고객ID
        this.lv_contr.chnlCstgrId = this.hoshInfoList[0].detail.chnlCstgrId // 계약자 채널그룹ID

        // 피보험자
        if (this.hoshInfoList[0].agtFileId !== this.hoshInfoList[1].agtFileId) { // 계피상이여부
          this.contrInsrdSameYn = 'N' // 계피상이
          // 피보험자 정보 설정 hoshInfoList[1]
          this.lv_insured.custNm = this.hoshInfoList[1].insrdNm // 피보험자 이름
          if (this.lv_insured.custNm.length > 4) { // 계약자 이름이 길어질 경우 tooltip표시 여부 확인
            this.lv_insured.showNameTooltip = true
          }
          this.lv_insured.age = this.hoshInfoList[1].age + '세' // 피보험자 나이
          this.lv_insured.custRrnId = this.hoshInfoList[1].custRrnId // 피보험자 주민번호
          this.lv_insured.custRrnId1 = this.hoshInfoList[1].custRrnId.substring(0, 6) // 피보험자 주민번호 앞자리
          this.lv_insured.custRrnId2 = this.hoshInfoList[1].custRrnId.substring(6, 7) + '******' // 피보험자 주민번호 뒷 한자리 + 마스킹
          this.lv_insured.custCelno = this.hoshInfoList[1].detail.celno // 계약자 전화번호

          this.lv_insured.chnlCustId = this.hoshInfoList[1].detail.chnlCustId // 피보험자 채널고객ID
          this.lv_insured.chnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 피보험자 채널그룹ID
          if (this.lv_insured.custNm === '태아' && this.lv_insured.custRrnId === '0000003000000') {
            this.lv_insured.isUnch = true
          }
        } else { // 계피동일
          this.contrInsrdSameYn = 'Y' // 계피동일
          this.lv_insured = this.lv_contr
        }

        // 종피
        if (this.hoshInfoList.length >= 3 && this.hoshInfoList[2].contvInsrdCd === '23') { // 종피보험자(23)
          this.showAddInsuredRow = true // 종피보험자 열 표시

          this.lv_addInsured.name = this.hoshInfoList[2].insrdNm // 피보험자 이름
          if (this.lv_addInsured.name.length > 4) { // 계약자 이름이 길어질 경우 tooltip표시 여부 확인
            this.lv_addInsured.showNameTooltip = true
          }
          this.lv_addInsured.age = this.hoshInfoList[2].age + '세' // 피보험자 나이
          this.lv_addInsured.knb = this.hoshInfoList[2].custRrnId // 피보험자 주민번호 : BPID 조회시 사용
          this.lv_addInsured.custRrnId = this.hoshInfoList[2].custRrnId // 피보험자 주민번호
          this.lv_addInsured.custRrnId1 = this.hoshInfoList[2].custRrnId.substring(0, 6) // 피보험자 주민번호 앞자리
          this.lv_addInsured.custRrnId2 = this.hoshInfoList[2].custRrnId.substring(6, 7) + '******' // 피보험자 주민번호 뒷 한자리 + 마스킹

          // this.lv_addInsured.custCelno = this.hoshInfoList[2].detail.celno // 계약자 전화번호
          this.lv_addInsured.chnlCustId = this.hoshInfoList[2].agtFileId // 피보험자 채널고객ID
          // this.lv_addInsured.chnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 피보험자 채널그룹ID
          this.fn_ServiceData('S2', this.lv_addInsured) // 종피보험자 정보조회
        } else {
          this.showAddInsuredRow = false // 종피보험자 열 표시
        }

        // 친권1, 친권2 표시 : 계약자 or 피보험자 미성년자인 경우
        if ( !this.$bizUtil.compareCurrentAge(this.hoshInfoList[0].custRrnId.substr(0, 6), this.standardAge19) ||
             !this.$bizUtil.compareCurrentAge(this.hoshInfoList[1].custRrnId.substr(0, 6), this.standardAge19) ) {
          this.showPrtr1Row = true // 친권1
          this.showPrtr2Row = true // 친권2
        }

        // 세대원목록 조회
        this.fn_selHouseholdMemberList()

        // fc정보 조회
        // this.fn_selFcInfo()
        // 위치변경 .
        // // 지정대리청구인 지정가능여부 // 위치변경 
        // this.isAsgnProxyClamPsb = this.lv_InputData.isAsgnProxyClamPsb
        // if ( this.isAsgnProxyClamPsb ) {
        //   // 보험유형관리코드RD (2021.05.31_PJO)
        //   this.zzInsrTypMgId = this.lv_InputData.zzInsrTypMgId
        //   this.fn_Init_ApcAsgn()
        // }

        this.isAmlDthBnfr01 = PSAmlUtil.fn_IsAmlPrfmc('3',this.lv_InputData.jsonInputData) // 사망수익자01 AML 대상여부

        //AML 사망수익자 채널고객ID 구하기
        if (this.isAmlDthBnfr01) {
          let dthBnfr01Info = await PSAmlUtil.fn_GetBnfrInfo(this, event.bnfrInfoList)
          if(dthBnfr01Info){
            this.amlDthBnfr01_chnlCustId = dthBnfr01Info.agtFileId	//AML사망수익자채널고객ID
          }
        }

        // 수익자
        let bnfrInfoList = event.bnfrInfoList
        this.amlBnfrInfoList =  event.bnfrInfoList
        let bnfrInfo1_custNm = new Set()
        let bnfrInfo2_custNm = new Set()
        //console.log('bnfrInfoList####### : ' + JSON.stringify(bnfrInfoList))
        
        if (bnfrInfoList) {
          this.bnfrInfo35 = []
                
          for (let i = 0; i < bnfrInfoList.length; i++ ) {
            let rrn = { 
              knbEncr1 : bnfrInfoList[i].custRrnId.substring(0, 6), // 주민번호 앞자리
              knbEncr2 : bnfrInfoList[i].custRrnId.substring(6, 7) + '******'
            }
            switch (bnfrInfoList[i].bnfrRolCd) {
              case '31': // 만기수익자
                this.bnfrInfo31 = Object.assign(bnfrInfoList[i], rrn)
                this.isBnfrInfo31 = true
                bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
                break
              case '32': // 분할수익자
                this.bnfrInfo32 = Object.assign(bnfrInfoList[i], rrn)
                this.isBnfrInfo32 = true
                bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
                break
              case '33': // 연금수익자
                this.bnfrInfo33 = Object.assign(bnfrInfoList[i], rrn)
                this.isBnfrInfo33 = true
                bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
                break
              case '34': // 상해수익자
                this.bnfrInfo34 = Object.assign(bnfrInfoList[i], rrn)
                this.isBnfrInfo34 = true
                bnfrInfo2_custNm.add(bnfrInfoList[i].custNm)
                break
              case '35': // 사망수익자
                this.bnfrInfo35.push(Object.assign(bnfrInfoList[i], rrn))
                this.isBnfrInfo35 = true
                bnfrInfo2_custNm.add(bnfrInfoList[i].custNm)
                break
              case '39': // 연금후수익자
                this.bnfrInfo39 = Object.assign(bnfrInfoList[i], rrn)
                this.isBnfrInfo39 = true
                bnfrInfo1_custNm.add(bnfrInfoList[i].custNm)
                break
            }
          }
          this.bnfrInfo1_custNm = Array.from(bnfrInfo1_custNm).toString()
          this.bnfrInfo2_custNm = Array.from(bnfrInfo2_custNm).toString()
        }

      } else { // if (hoshInfoList) { 종료
        console.log('#### fn_ServiceDataResultHandler 오류')
      }
    },
    
    /******************************************************************************
    * Function명 : fn_selHouseholdMemberList
    * 설명       : 세대원 목록 조회
    ******************************************************************************/
    fn_selHouseholdMemberList () {
      // 1. 세대원 목록 초기화
      this.householdMemberList = []
      this.householdMemberListNotContr = []
      this.householdMembers_apc = [] // 지정대리청구_가족카드리스트(간편지대청 입력추가됨)
      // 2. 세대원 목록 조회
      let pParams = {
        'cnsltNo': this.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
        'chnlCstgrId': this.lv_contr.chnlCstgrId // 채널고객그룹ID
      }
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
      }

      let trnstId = 'txTSSCM10S5' // 정보활용동의고객상세조회
      let auth = 'S'
      let lv_Vm = this

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body.iCCustInfoPrcusCnsntVO !== null) {
            // 2.1 세대원 목록 설정
            let t_CnsntList = response.body.iCCustInfoPrcusCnsntVO
            let i = 0
            let currentYmd = PSDateUtil.fn_CurrentDate()

            // 2.2 반복, 세대원 목록 구성
            t_CnsntList.forEach((item) => {
              let t_member = {}
              i++

              // 2.2.1 화면 표시영역
              t_member.key = i.toString()
              // t_member.label = lv_Vm.$bizUtil.omitStr(item.custNm, 4)
              t_member.label = item.custNm
              t_member.style = true // 필수컨설팅동의 : N

              // 2.2.2 데이터 영역
              t_member.name = item.custNm
              t_member.chnlCustId = item.chnlCustId
              t_member.knbEncr = item.knbEncr // 주민등록번호 암호화 : YYMMDD1******
              t_member.knbEncr1 = item.knbEncr.substring(0, 6)
              t_member.knbEncr2 = item.knbEncr.substring(6, 12)
              t_member.celno = item.celno
              t_member.isMcnstYn = 'N' // 필수컨설팅동의여부

              // 정보활용동의일자14 = 필수컨설팅동의일자
              if (item.infoPrcusCnsntYmd14 >= currentYmd) {
                t_member.style = false // 필수컨설팅동의 : Y
                t_member.isMcnstYn = 'Y' // 필수컨설팅동의여부
              }

              // 2.3 계약자의 채널고객 ID, 세대원의 채널고객ID가 일치 && 계약자가 미성년자가 아닌 경우
              //     예금주 초기값으로 설정
              if (lv_Vm.lv_contr.chnlCustId === t_member.chnlCustId && lv_Vm.$bizUtil.compareCurrentAge(lv_Vm.lv_contr.custRrnId.substr(0, 6), lv_Vm.standardAge19)) {
                lv_Vm.lv_dpstrSel = lv_Vm.$bizUtil.cloneDeep(t_member)
                lv_Vm.dPstrContrFmrtSel = {key: '01', label: '본인'}
              }

              // 가족 구성원 목록에 1건씩 추가
              // lv_Vm.householdMemberList.push(lv_Vm.$bizUtil.cloneDeep(t_member)) //부분적으로 v-model 키값 물리는 연동 문제로 복사본 떠서 List에추가
              lv_Vm.householdMemberList.push(t_member) 

              // 가족 구성원 목록추가(계약자 제외)
              if (lv_Vm.lv_contr.chnlCustId !== t_member.chnlCustId) {
                // lv_Vm.householdMemberListNotContr.push(lv_Vm.$bizUtil.cloneDeep(t_member))
                lv_Vm.householdMemberListNotContr.push(t_member)
                lv_Vm.householdMembers_apc.push(t_member) // 간편지대청
              }
            })
            // 지정대리청구 가능할 경우
            if ( lv_Vm.isAsgnProxyClamPsb ) { 
              lv_Vm.fn_Init_ApcHouseholdMember()
            }
          } else {
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
          // fc정보 조회
          lv_Vm.fn_selFcInfo()
          // 청약url표시 검증
          lv_Vm.fn_urlTrnsShowCheck()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /*********************************************************
     * Function명: fn_Init_ApcHouseholdMember
     * 설명       : N01일경우 가족카드목록 '선택하세요','간편지정대리청구인'추가  / (2024.04.23_PJO)
     *********************************************************/
    fn_Init_ApcHouseholdMember () {
      // N01일경우 가족카드목록 첫번째 인덱스에 '직접입력'추가 
      if ( this.zzInsrTypMgId === 'N01' ) {
        this.householdMembers_apc.splice(0,0, // 첫번째 인덱스에 추가 // 20240402
          {
          key: '', // 순번
          label: '선택하세요', // 이름 4글자
          name: '', // 이름
          chnlCustId: '', // 채널고객ID
          knbEncr: '', // 주민등록번호 암호화 : YYMMDD1******
          knbEncr1: '', // 주민번호 앞자리
          knbEncr2: '', // 주민번호 뒷자리
          celno: '', // 연락처
          isMcnstYN: 'N', // 필수컨설팅동의여부
          style: true, // 스타일 , 필수컨설팅동의:N -> true
          },
          {
          key: 'SIMPLE', // 순번
          label: '간편지정대리청구인', // 이름 4글자
          name: '간편지정대리청구인', // 이름
          chnlCustId: '', // 채널고객ID
          knbEncr: '', // 주민등록번호 암호화 : YYMMDD1******
          knbEncr1: '', // 주민번호 앞자리
          knbEncr2: '', // 주민번호 뒷자리
          celno: '', // 연락처
          isMcnstYN: 'N', // 필수컨설팅동의여부
          style: true, // 스타일 , 필수컨설팅동의:N -> true
         }
        )
        // ASR240200999_간편지대청 도입(김문준/240423)
        // ⑥ 선택하세요 → "1인지정"으로 디폴트(간편지정대리청구인 활성화와 동일 요건(치매급부 있는 계약에서만 적용))
        // ⑦ 지정대리청구인1 "간편지정대리청구인" 으로 디폴트
        this.isApcAsgnView = true // 지대청1  활성화
        this.isApcAsgnView2 = false // 지대청2 비활성화
        this.apcPrsonAplScSel = {key: '01', label: '1인지정'}
        this.fn_SetApc1 ({key: 'SIMPLE', label: '간편지정대리청구인'} )
      } else {
        this.fn_SetApc1 ({key: '', label: ''} )
      }
    },
    /******************************************************************************
    * Function명 : fn_selFcInfo
    * 설명       : FC정보조회
    *            : FC전화번호는 getStore('userInfo')에 가지고 있지 않음
    ******************************************************************************/
    fn_selFcInfo () {
      let lv_Vm = this
      let pParams = {userEno: this.getStore('userInfo').getters.getUserInfo.userId}
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.userEno = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList = [{stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo}]
      }
      const trnstId = 'txTSSBC20S3'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          let fc_UserVo = response.body
          lv_Vm.lv_fc.fcNm = fc_UserVo.userNm
          if (lv_Vm.lv_fc.fcNm.length > 4) { // FC 이름이 길어질 경우 tooltip표시 여부 확인
            lv_Vm.lv_fc.showNameTooltip = true
          }
          // 전화번호는 00011112222 형태이기때문에, -을 추가하기 위해서 util 호출
          lv_Vm.lv_fc.fcCelno = lv_Vm.$bizUtil.telNoWithHyphen(fc_UserVo.celno.trim())
          lv_Vm.lv_fc.fcCelno2 = fc_UserVo.celno.trim()
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
    * Function명  : fn_ServiceData
    * 설명        : 서비스 호출 함수 정의
    ******************************************************************************/
    fn_ServiceData: function (serviceName, inputData) {
      let pParams = {}
      switch (serviceName) {
        case 'S1': // 채널고객 기본 정보(예금주)
        case 'S2': // 채널고객 기본 정보(종피보험자)
        case 'S3': // 채널고객 기본 정보(친권1)
        case 'S4': // 채널고객 기본 정보(친권2)
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S4')) // 채널고객 정보
          pParams.context = this
          pParams.srnId = this.$options.screenId
          pParams.data = {}
          pParams.data.cnsltNo = PSServiceManager.getUserInfo('userId')
          // GA 매니저 대행 컨설턴트 변경시
          if(this.$cmUtil.fn_GachangeTF()){
            pParams.data.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
          }
          pParams.data.chnlCustId = inputData.chnlCustId
          pParams.data.custNm = inputData.name
          break
        case 'S1-2': // BPID 조회 (예금주)
        case 'S2-2': // BPID 조회 (종피보험자)
        case 'S3-2': // BPID 조회 (친권1)
        case 'S4-2': // BPID 조회 (친권2)
          pParams = Object.assign({}, PSServiceManager.getParameter('txTSSPS13S6')) // 고객기준정보
          pParams.data.BUSINESSPARTNER.GENERALDATA_S = {}
          pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_PERSON = {}
          pParams.data.BUSINESSPARTNER.GENERALDATA_S.BP_PERSON.LASTNAME = inputData.name
          pParams.data.BUSINESSPARTNER.IDNUMBERS_T = [{IDENTIFICATIONNUMBER: inputData.knb}]
          pParams.data.IS_HEADER.USERID = PSServiceManager.getUserInfo('userId')
          // GA 매니저 대행 컨설턴트 변경시
          if(this.$cmUtil.fn_GachangeTF()){
            pParams.data.IS_HEADER.USERID = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
          }
          break
        default:
          break
      } // end switch
      if (pParams.data !== null) {
        // 프로그래스바 표시
        //window.vue.getStore('progress').dispatch('UPDATE', true)
        pParams.srnId = this.$options.screenId // 대표 화면 명
        PSServiceManager.invoke(pParams, this.fn_ServiceResultHandler, serviceName, this.fn_ServiceFaultHandler, true)
      } else {
        this.fn_trace('error', serviceName + '::조회 Data오류')
      } // end else if
    },

    /************************************************************************************************
     * Function명  : fn_ServiceResultHandler
     * 설명        : 서비스 호출 결과 함수 정의
     ************************************************************************************************/
    fn_ServiceResultHandler: function (event, serviceName) {
      let lv_data = event.data
      switch (serviceName) {
        case 'S1': // 채널고객 기본 정보(예금주)
          this.lv_dpstrSel.knb = lv_data.knb // 주민번호 조회
          this.lv_dpstrSel.chnlCstgrId = lv_data.chnlCstgrId
          this.fn_ServiceData('S1-2', this.lv_dpstrSel) // 예금주 BPID 조회정보조회
          break
        case 'S1-2': // BPID 조회(예금주)
          this.lv_dpstrSel.partnerId = lv_data.businesspartner.partner
          // BPID조회순서 (예금주 -> 친권1 -> 친권2)
          if (this.showPrtr1Row) {
            if (this.$bizUtil.isEmpty(this.lv_Prtr1Sel.chnlCustId)) {
              this.isMobpsValMessge = '친권자1을 선택하지 않았습니다.'
              //this.isMobpsValCheck = true
              this.fn_ConfirmMsg(this.isMobpsValMessge)
            } else {
              this.fn_ServiceData('S3', this.lv_Prtr1Sel) // 친권1
            }
          } else {
            this.fn_kakaoIapDspchSnProc() // 카카오알림톡 발송 순번 지정
          }
          break
        case 'S2': // 채널고객 기본 정보(종피보험자)
          this.lv_addInsured.custCelno = lv_data.celno // 종피보험자 전화번호
          this.lv_addInsured.chnlCstgrId = lv_data.chnlCstgrId // 종피보험자 채널그룹ID
          this.fn_ServiceData('S2-2', this.lv_addInsured) // 종피보험자 BPID 조회정보조회
          break
        case 'S2-2': // BPID 조회(종피보험자)
          this.lv_addInsured.partnerId = lv_data.businesspartner.partner
          break
        case 'S3': // 채널고객 기본 정보(친권1)
          this.lv_Prtr1Sel.knb = lv_data.knb // 주민번호 조회
          this.lv_Prtr1Sel.chnlCstgrId = lv_data.chnlCstgrId
          this.fn_ServiceData('S3-2', this.lv_Prtr1Sel) // 예금주 BPID 조회정보조회
          break
        case 'S3-2': // BPID 조회(친권1)
          this.lv_Prtr1Sel.partnerId = lv_data.businesspartner.partner
          // if (this.prtr2ContrFmrtSel.key === '90') {
          if (this.singlePrtrCheckbox === true) {
            // 90 1인친권인
            this.fn_kakaoIapDspchSnProc() // 카카오알림톡 발송 순번 지정
          } else {
            if (this.$bizUtil.isEmpty(this.lv_Prtr2Sel.chnlCustId)) {
              this.isMobpsValMessge = '친권자2를 선택하지 않았습니다.'
              //this.isMobpsValCheck = true
              this.fn_ConfirmMsg(this.isMobpsValMessge)
            } else {
              this.fn_ServiceData('S4', this.lv_Prtr2Sel) // 친권2 BPID 조회정보조회
            }
          }
          break
        case 'S4': // 채널고객 기본 정보(친권2)
          this.lv_Prtr2Sel.knb = lv_data.knb // 주민번호 조회
          this.lv_Prtr2Sel.chnlCstgrId = lv_data.chnlCstgrId
          this.fn_ServiceData('S4-2', this.lv_Prtr2Sel) // 예금주 BPID 조회정보조회
          break
        case 'S4-2': // BPID 조회(친권2)
          this.lv_Prtr2Sel.partnerId = lv_data.businesspartner.partner
          this.fn_kakaoIapDspchSnProc() // 카카오알림톡 발송 순번 지정
          break
        default:
          break
      }
    },
    /************************************************************************************************
     * Function명  : fn_ServiceFaultHandler
     * 설명        : 에러 이벤트
     ************************************************************************************************/
    fn_ServiceFaultHandler: function (event, serviceName) {
      switch (serviceName) {
        case 'S1':
        case 'S1-2':
        case 'S2':
        case 'S2-2':
        case 'S3':
        case 'S3-2':
          this.isMobpsValMessge = '고객정보 조회시 오류가 발생하였습니다.'
          break
        default:
          break
      }
      //this.isMobpsValCheck = true
      this.fn_ConfirmMsg(this.isMobpsValMessge)
    },

    /******************************************************************************
    * Function명 : fn_mobpsPblProc
    * 설명       : 모바일청약 발행 처리
    ******************************************************************************/
    async fn_mobpsPblProc () {
      let lv_Vm = this
      let param = {}
      const trnstId = 'txTSSPS50P6' //AML대응 'txTSSPS50P1'
      const auth = 'I'

      param = {
        // TTSD00001
        'vuchId': '', // 영수증ID
        'elstPlanId': '', // 전자서명설계ID
        'clctCnsltNo': '', // 모집컨설턴트번호
        'contrInsrdSameYn': '', // 계약자피보험자동일여부
        'mnisdMiorYn': '', // 주피보험자미성년자여부
        'unchYn': '', // 태아여부
        'moblCswfYn': '', // 모바일완전판매여부
        'insrOfrYmd': '', // 보험청약일자
        'insrdTrtyFgr': '', // 피보험자특약수
        'aisdTrtyFgr': '', // 종피보험자특약수
        'secusFrmCd': '', // 증권양식코드
        'untyCovrInsrTypCd': '', // 통합보장보험유형코드
        'ptclrPrdtTypCd': '', // 특이상품유형코드
        'kliaInsrScCd': '', // 생보협회보험구분코드
        'pdinsRltnCstuCd': '', // 상품보험관계구성코드
        'contrDpstrSameYn': '', // 계약자예금주동일여부
        'insrdDpstrSameYn': '', // 피보험자예금주동일여부
        'dpstrIdpnYn': '', // 예금주독립여부
        'insrTypMngCd': '', // 보험유형관리코드RD (ASR210500282_2021.05.31 PJO)
        'prdtTypMgCd': '', // 상품유형관리코드RD (ASR220400794_22.05.10 PJO)

        // TTSD00002
        'elstPartnNo': '', // 전자서명파트너번호
        'elstInsrdPartnNo': '', // 전자서명피보험자파트너번호
        'mobslChnlCustId': '', // 모바일영업채널고객ID
        'mobslChnlCstgrId': '', // 모바일영업채널고객그룹ID
        'insrdChnlCustId': '', // 피보험자채널고객ID
        'mnContrNm': '', // 주계약자명
        'insrdNm': '', // 피보험자명
        'prcd': '', // 상품코드
        'mnPrdtNm': '', // 주상품명
        'contPrm': '', // 계약보험료
        'isuMthScCd': '', // 교부방법구분코드
        'aftRctmHopYn': '', // 후입금희망여부
        'coprClctYn': '', // 공동모집여부
        'crncyCd': 'KRW', // 통화키
        //AML 대응
        'amlContrPartnNo' : '' ,  // AML계약자파트너번호
        'amlPrtrPesonPartnNo' : '' , //AML친권자파트너번호
        'amlDthBnfrPartnNo' : '' , //AML사망수익자파트너번호
        'amlDthBnfrChnlCustId' : '' , //AML사망수익자채널고객ID
        'amlDthBnfrCustNm' : '' , //AML사망수익자고객명
        
        // TTSD02003
        'jsonInputData': '', // OZ jsonInputData

        'isSendKakao': this.isSendKakao // 카카오 알림톡 발송여부
      }

      // TTSD00001 : TSSPS110M.fn_InsEltrnSignPrgInfo
      param.vuchId = this.lv_InputData.moDCDocuMngVO.eltrnDoctId // 영수증ID
      param.elstPlanId = this.lv_InputData.entplId // 전자서명설계ID
      param.clctCnsltNo = this.$MenuManager.fn_GetUserInfoProp('userId') // 모집컨설턴트번호
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        param.clctCnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      param.contrInsrdSameYn = (this.hoshInfoList[0].agtFileId === this.hoshInfoList[1].agtFileId) ? 'Y' : 'N' // 계약자피보험자동일여부
      param.mnisdMiorYn = (this.$bizUtil.compareCurrentAge(this.hoshInfoList[1].custRrnId.substr(0, 6), this.standardAge19)) ? 'N' : 'Y' // 주피보험자미성년자여부
      param.unchYn = (this.hoshInfoList[1].custRrnId === '0000003000000') ? 'Y' : 'N' // 태아여부
      param.moblCswfYn = this.lv_InputData.isMoblPftSale ? 'Y' : 'N' // 모바일완전판매여부

      param.insrOfrYmd = PSDateUtil.fn_CurrentDate() // 보험청약일자
      param.insrdTrtyFgr = (this.lv_InputData.insrdTrtyFgr === null || this.lv_InputData.insrdTrtyFgr === '') ? '0' : String(this.lv_InputData.insrdTrtyFgr) // 피보험자특약수
      param.aisdTrtyFgr = (this.lv_InputData.aisdTrtyFgr === null || this.lv_InputData.aisdTrtyFgr === '') ? '0' : String(this.lv_InputData.aisdTrtyFgr) // 종피보험자특약수
      param.secusFrmCd = (this.lv_InputData.zzSecusFrmId === undefined || this.lv_InputData.zzSecusFrmId === '') ? ' ' : this.lv_InputData.zzSecusFrmId // 증권양식코드
      param.untyCovrInsrTypCd = (this.lv_InputData.zzUntyCoITId === undefined || this.lv_InputData.zzUntyCoITId === '') ? ' ' : this.lv_InputData.zzUntyCoITId // 통합보장보험유형코드
      param.ptclrPrdtTypCd = (this.lv_InputData.zzPtclrPrdTId === undefined || this.lv_InputData.zzPtclrPrdTId === '') ? ' ' : this.lv_InputData.zzPtclrPrdTId // 특이상품유형코드
      param.kliaInsrScCd = (this.lv_InputData.zzKliaPrdtId === undefined || this.lv_InputData.zzKliaPrdtId === '') ? ' ' : this.lv_InputData.zzKliaPrdtId // 생보협회보험구분코드
      // 생보협회상품분류값이 3자리로 넘어오면 첫번째를 자르고 뒤에 두개의 유효값만 사용한다

      param.kliaInsrScCd = (typeof (param.kliaInsrScCd) === 'string') ? String(Number(param.kliaInsrScCd)) : String(param.kliaInsrScCd)
      if (param.kliaInsrScCd.length > 2) {
        param.kliaInsrScCd = param.kliaInsrScCd.substr(1, 2)
      } else if (param.kliaInsrScCd.length === 0 || param.kliaInsrScCd === '0') {
        param.kliaInsrScCd = ' '
      } else if (param.kliaInsrScCd.length === 1) {
        param.kliaInsrScCd = '0' + param.kliaInsrScCd
      }

      param.pdinsRltnCstuCd = (this.lv_InputData.zzPdinsRCCId === undefined || this.lv_InputData.zzPdinsRCCId === '') ? ' ' : this.lv_InputData.zzPdinsRCCId // 상품보험관계구성코드
      param.contrDpstrSameYn = (this.hoshInfoList[0].agtFileId === this.lv_dpstrSel.chnlCustId) ? 'Y' : 'N' // 계약자예금주동일여부
      param.insrdDpstrSameYn = (param.contrDpstrSameYn === 'N' && this.hoshInfoList[1].agtFileId === this.lv_dpstrSel.chnlCustId) ? 'Y' : 'N' // 피보험자예금주동일여부
      param.dpstrIdpnYn = (param.contrDpstrSameYn === 'N' && param.insrdDpstrSameYn === 'N') ? 'Y' : 'N' // 예금주독립여부

      param.ernam = this.$MenuManager.fn_GetUserInfoProp('userId') // 생성자id
      param.aenam = this.$MenuManager.fn_GetUserInfoProp('userId') // 변경자id
      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        param.ernam = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
        param.aenam = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }

      param.insrTypMngCd = this.zzInsrTypMgId // 보험유형관리코드RD (ASR210500282_2021.05.31 PJO)
      param.prdtTypMgCd = (!this.$bizUtil.isEmpty(this.lv_InputData.zzPrdtTypMgId)) ? this.lv_InputData.zzPrdtTypMgId : ' ' // 상품유형관리코드RD (ASR220400794_22.05.10 PJO)
      param.prmPadTypValD = (!this.$bizUtil.isEmpty(this.lv_InputData.zzPrmPadTVId)) ? this.lv_InputData.zzPrmPadTVId : ' ' // 보험료납입유형값RD (ASR240400333_24.05 PJO/장한나)

      // TTSD00002 : TSSPS110M.fn_InsElstEtlInfo
      param.elstPartnNo = this.hoshInfoList[0].partnerId // 전자서명파트너번호
      param.elstInsrdPartnNo = this.hoshInfoList[1].partnerId // 전자서명피보험자파트너번호
      param.mobslChnlCustId = this.hoshInfoList[0].agtFileId // 모바일영업채널고객ID
      param.mobslChnlCstgrId = (this.hoshInfoList[0].detail.chnlCstgrId) ? this.hoshInfoList[0].detail.chnlCstgrId : ' ' // 모바일영업채널고객그룹ID
      param.insrdChnlCustId = this.hoshInfoList[1].agtFileId // 피보험자채널고객ID
      param.mnContrNm = this.hoshInfoList[0].insrdNm // 주계약자명
      param.insrdNm = (this.hoshInfoList[0].agtFileId !== this.hoshInfoList[1].agtFileId) ? this.hoshInfoList[1].insrdNm : this.hoshInfoList[0].insrdNm // 피보험자명
      param.prcd = this.planInfoData[0].prcdId || ' ' // 상품코드
      param.mnPrdtNm = this.lv_InputData.moDCDocuMngVO.prdtNm || ' ' // 주상품명
      param.contPrm = this.planInfoData[0].smtotPrm // 계약보험료
      param.isuMthScCd = '02' // 교부방법구분코드 02:지류로 등록
      // this.lv_InputData.moDCDocuMngVO.fstIsuMthScCd || ' ' // 최초교부방법구분코드
      param.aftRctmHopYn = (this.lv_InputData.zzaftRctmFg === '1') ? 'Y' : 'N' // 후입금희망여부
      param.coprClctYn = this.lv_InputData.coprClctYn ? 'Y' : 'N' // 공동모집여부

      param.dpstrMobslChnlCustId = this.lv_dpstrSel.chnlCustId // 예금주모바일영업채널고객ID
      param.dpstrNm = this.lv_dpstrSel.name // 예금주명
      param.dpstrRltnCd = this.dPstrContrFmrtSel.key // 계약자/예금주관계ID / 01:본인, 02:배우자, 03:부모, 04:자녀
      param.crncyCd = this.lv_InputData.moDCDocuMngVO.currencyId // 통화코드

      // 지정대리청구
      param.apcAsgnYn = 'N' // 지정대리청구지정여부
      param.apcPrsonAplScCd = '' // 지정대리청구인신청구분코드(20210531_PJO)
      param.apcChnlCustId = '' // 지정대리청구 모바일영업채널고객ID
      param.apcCustNm = '' // 지정대리청구명
      param.contrRltnCd = '' // 계약자관계코드
      // 2인지정가능
      param.apcChnlCustId02 = '' // 지정대리청구 모바일영업채널고객ID2
      param.apcCustNm02 = '' // 지정대리청구명2
      param.contrRltnCd02 = '' // 계약자관계코드2

      // ASR240200999_간편지대청 도입(김문준/240423)
      param.simpAsgnClamAgntCd01 = '' // 지정대리인1_입력유형
      param.simpAsgnClamAgntCd02= '' // 지정대리인2_입력유형
      
      if (this.isAsgnProxyClamPsb) { // 지정대리청구인 지정가능여부(계==피)
        if( !this.fn_ChkApcValidation() ) {
          console.log('---------지대청 체크 false')
          return
        }
        console.log('----------흐르니?')
        // param.apcPrsonAplScCd = this.apcPrsonAplScCd // 지정대리청구인신청구분코드(20210531_PJO)
        param.apcPrsonAplScCd = this.apcPrsonAplScSel.key // ASR240200999_간편지대청 도입(김문준/240423)
        if (this.isApcAsgnView) {
          param.apcAsgnYn = 'Y'
          param.simpAsgnClamAgntCd01 = this.simpAsgnClamAgntCd01
          if ( this.simpAsgnClamAgntCd01 == 'A')  {
            param.apcChnlCustId = this.lv_asgnProxyClamInfoSel.chnlCustId
            param.apcCustNm = this.lv_asgnProxyClamInfoSel.name

            // 지정대리청구인 계약자관계코드
            let tmpCdKey = this.asgnProxyClamContrRltnScCdSel.key
            if (tmpCdKey === '61' || tmpCdKey === '62' || tmpCdKey === '63' || tmpCdKey === '64' || tmpCdKey === '65' ||
                tmpCdKey === '66' || tmpCdKey === '66' || tmpCdKey === '67' || tmpCdKey === '68' ) {
              param.contrRltnCd = '06' // 계약자관계코드 06:친족
            } else {
              param.contrRltnCd = tmpCdKey
            }
          } else if ( this.simpAsgnClamAgntCd01 == 'B')  { // 직접입력
            param.apcChnlCustId = ''
            param.apcCustNm = this.apcCustNm_simple1
            param.contrRltnCd = this.apclamInfoSel_simple1.key
          }
        }
        // 지정대리청구인_2인가능_수정_START(ASR210500282_20210531_PJO)
        if (this.isApcAsgnView2) {
          param.simpAsgnClamAgntCd02 = this.simpAsgnClamAgntCd02
          if ( this.simpAsgnClamAgntCd02 == 'A')  {
            param.apcChnlCustId02 = this.lv_asgnProxyClamInfoSel2.chnlCustId
            param.apcCustNm02 = this.lv_asgnProxyClamInfoSel2.name

            // 지정대리청구인 계약자관계코드
            let tmpCdKey = this.asgnProxyClamContrRltnScCdSel2.key
            if (tmpCdKey === '61' || tmpCdKey === '62' || tmpCdKey === '63' || tmpCdKey === '64' || tmpCdKey === '65' ||
                tmpCdKey === '66' || tmpCdKey === '66' || tmpCdKey === '67' || tmpCdKey === '68' ) {
              param.contrRltnCd02 = '06' // 계약자관계코드 06:친족
            } else {
              param.contrRltnCd02 = tmpCdKey
            }
          } else if ( this.simpAsgnClamAgntCd02 == 'B') {
            param.apcChnlCustId02 = '' // 지정대리청구 모바일영업채널고객ID2
            param.apcCustNm02 = this.apcCustNm_simple2 // 지정대리청구명2
            param.contrRltnCd02 = this.apclamInfoSel_simple2.key // 계약자관계코드2
          }
        }
      } // 지정대리청구_end
      console.log('>>지대청정보')
      console.log('apcAsgnYn:' + param.apcAsgnYn)
      console.log('apcPrsonAplScCd:' + param.apcPrsonAplScCd)
      // 1인
      console.log('1인')
      console.log('simpAsgnClamAgntCd01:' + param.simpAsgnClamAgntCd01)
      console.log('apcChnlCustId:' + param.apcChnlCustId)
      console.log('apcCustNm:' + param.apcCustNm)
      console.log('contrRltnCd:' + param.contrRltnCd)
      // 2인
      console.log('2인')
      console.log('simpAsgnClamAgntCd02:' + param.simpAsgnClamAgntCd02)
      console.log('apcChnlCustId02:' + param.apcChnlCustId02)
      console.log('apcCustNm02:' + param.apcCustNm02)
      console.log('contrRltnCd02:' + param.contrRltnCd02)

/*  asis / vaildation check  -> fn_ChkApcValidation
      // 지정대리청구인 세팅하지 않아도, 지정대리 청구 가능시에는 선택값을 저장한다.
      // ex)  지정대리청구지정여부(apcAsgnYn) N 이어요  , 지정대리청구인신청구분코드(apcPrsonAplScCd) , M1(미지정) M2(지정불가) 는 저장

      if (this.isAsgnProxyClamPsb) { // 지정대리청구인 지정가능여부(계==피)
        if (this.apcPrsonAplScSel.key === '' ) {
          this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인을 선택해 주세요.') //문구수정(ASR231001107_231107_PJO)
          return
        }
        param.apcPrsonAplScCd = this.apcPrsonAplScCd // 지정대리청구인신청구분코드(20210531_PJO)

        // ASR240200999_간편지대청 도입(김문준/240423)
        if (this.show1 && this.simpAsgnClamAgntCd01 == 'B' && ( this.apcCustNm_simple1.trim() === '' ||  this.apclamInfoSel_simple1.key.trim() === '')) {
          this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인 정보를 입력하세요!.') //문구수정(ASR231001107_231107_PJO)
          rst = false
          return false
        }
      }
      if (this.isApcAsgnView) { // 지정대리청구인 선택여부

        if (this.$bizUtil.isEmpty(this.lv_asgnProxyClamInfoSel.chnlCustId)) {
          this.isMobpsValMessge = '지정대리청구인을 선택하지 않았습니다.'
          //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
          return
        }
        if (this.asgnProxyClamContrRltnScCdSel.key.trim() === '') {
          this.isMobpsValMessge = '지정대리청구인의 피보험자와의 관계가<br>선택하지 않았습니다.'
          //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
          return
        }
        // 2인
        if (this.isApcAsgnView2) {
          if (this.$bizUtil.isEmpty(this.lv_asgnProxyClamInfoSel2.chnlCustId)) {
            this.isMobpsValMessge = '지정대리청구인(2)을 선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
          if (this.$bizUtil.isEmpty(this.asgnProxyClamContrRltnScCdSel2.key)) {
            this.isMobpsValMessge = '지정대리청구인(2)의 피보험자와의 관계가<br>선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
        }

        param.apcAsgnYn = 'Y'
        param.apcChnlCustId = this.lv_asgnProxyClamInfoSel.chnlCustId
        param.apcCustNm = this.lv_asgnProxyClamInfoSel.name

        // 지정대리청구인 계약자관계코드
        let tmpCdKey = this.asgnProxyClamContrRltnScCdSel.key
        if (tmpCdKey === '61' || tmpCdKey === '62' || tmpCdKey === '63' || tmpCdKey === '64' || tmpCdKey === '65' ||
            tmpCdKey === '66' || tmpCdKey === '66' || tmpCdKey === '67' || tmpCdKey === '68' ) {
          param.contrRltnCd = '06' // 계약자관계코드 06:친족
        } else {
          param.contrRltnCd = tmpCdKey
        }

        // 지정대리청구인_2인가능_수정_START(ASR210500282_20210531_PJO)
        if (this.isApcAsgnView2) {
          param.apcChnlCustId02 = this.lv_asgnProxyClamInfoSel2.chnlCustId
          param.apcCustNm02 = this.lv_asgnProxyClamInfoSel2.name

          // 지정대리청구인 계약자관계코드
          tmpCdKey = this.asgnProxyClamContrRltnScCdSel2.key
          if (tmpCdKey === '61' || tmpCdKey === '62' || tmpCdKey === '63' || tmpCdKey === '64' || tmpCdKey === '65' ||
              tmpCdKey === '66' || tmpCdKey === '66' || tmpCdKey === '67' || tmpCdKey === '68' ) {
            param.contrRltnCd02 = '06' // 계약자관계코드 06:친족
          } else {
            param.contrRltnCd02 = tmpCdKey
          }
        }
      } // 지정대리청구_end
*/
      // 피보험자동의대상
      // Y:계약자와 피보험자가 불일치 || 종피보험자가 있는 경우
      // param.insrdCnsntObjYn = ( this.lv_InputData.zaElectAppTargetProductFgRD !== '2' && (this.hoshInfoList[0].agtFileId !== this.hoshInfoList[1].agtFileId || this.showAddInsuredRow)) ? 'Y' : 'N'

      // (ASR220400794_22.05.10 PJO)_START
      // - 변경
      // . 계피상이 OR 종피존재 = Y
      // . (계피상이 OR 종피존재) AND ( ZZPRDT_TYP_MG_ID = W1 OR ZZELT_O_O_P_Y_ID =2) = N  // 꿈나무 상품
      // . 그외는 모두 N
      param.insrdCnsntObjYn = 'N'
      // Y:계약자와 피보험자가 불일치 || 종피보험자가 있는 경우
      if (this.hoshInfoList[0].agtFileId !== this.hoshInfoList[1].agtFileId || this.showAddInsuredRow) {
        param.insrdCnsntObjYn = 'Y'
        let _zzPrdtTypMgId = (!this.$bizUtil.isEmpty(this.lv_InputData.zzPrdtTypMgId)) ? this.lv_InputData.zzPrdtTypMgId : ' ' // 상품유형관리코드RD (ZZPRDT_TYP_MG_ID)
        let _zaElectAppTargetProductFgRD = (!this.$bizUtil.isEmpty(this.lv_InputData.zaElectAppTargetProductFgRD)) ? this.lv_InputData.zaElectAppTargetProductFgRD : ' ' // 전자청약대상상품여부 (ZZELT_O_O_P_Y_ID)
        // ------------------------------------------------------START
        // ASR230300950 / 상품유형관리코드RD Rule Service 호출 (다중값 적용-가입설계)
        // _zzPrdtTypMgId = ' ' // 'W0,W1,W2' // 임시 테스트용
        let _zzPrdtTypMgIdFilter = ' '
        let splitList = _zzPrdtTypMgId.split(',')
        for (let i = 0; i < splitList.length; i++) {
          console.log('[' + i + ']' + splitList[i].trim())
          if ( splitList[i].trim() === 'W1' ) {
            _zzPrdtTypMgIdFilter = splitList[i].trim()
            break
          }
        }
        console.log('_zzPrdtTypMgIdFilter:=====================' + _zzPrdtTypMgIdFilter )
        // ------------------------------------------------------END
        if (_zzPrdtTypMgIdFilter === 'W1' || _zaElectAppTargetProductFgRD === '2') {
        // if (_zzPrdtTypMgId === 'W1' || _zaElectAppTargetProductFgRD === '2') {
          param.insrdCnsntObjYn = 'N'
        }
      }
      console.log('# 피보험자동의대상:' + param.insrdCnsntObjYn)
      // (ASR220400794_22.05.10 PJO)_END
      param.insrdCnsntScCd = 'U' // 피보험자동의구분코드, U:미동의
      param.corpNomnPinsYn = 'N' // 법인명의개인보험여부

      // TTSD02002
      let mobpsRegContPtcpList = []

      /** 계약관계자 구분코드 1:계약자 */
      if (this.urlTrnsContrShow && this.$bizUtil.isEmpty(this.hoshInfoList[0].detail.celno)) {
        this.isMobpsValMessge = '계약자의 휴대폰 번호가 없습니다.'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
        return
      }
      let mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '1' // 계약관계인구분코드 / 1:계약자
      mobpsRegContPtcp.elstPartnNo = this.hoshInfoList[0].partnerId // 전자서명파트너번호
      mobpsRegContPtcp.mobslChnlCustId = this.hoshInfoList[0].agtFileId // 모바일영업채널고객ID
      mobpsRegContPtcp.mobslChnlCstgrId = this.hoshInfoList[0].detail.chnlCstgrId // 모바일영업채널고객그룹ID
      if (this.urlTrnsContrShow) { // 태아, 미성년자인 경우 전화번호 검증 제외
        mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[0].detail.celno, '-', '') // 계약관계인전화번호암호화
      } else {
        mobpsRegContPtcp.contPtcpTelnoEncr = '' // 계약관계인전화번호암호화
      }
      mobpsRegContPtcp.contPtcpRrnEncr = this.hoshInfoList[0].custRrnId // 계약관계인주민등록번호암호화
      mobpsRegContPtcp.mobslChnlCustNm = this.hoshInfoList[0].insrdNm // 모바일영업채널고객명
      mobpsRegContPtcp.MobpsContrRltnScCd = '01' // 모바일청약계약자관계구분코드, 01:본인
      mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypContr[0].key // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = this.kakaoIapDspchSnContr // 알림톡알림발송순번

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      /** 계약관계자 구분코드 2:피보험자 */
      if (this.urlTrnsInsuredShow && this.$bizUtil.isEmpty(this.hoshInfoList[1].detail.celno)) {
        this.isMobpsValMessge = '피보험자의 휴대폰 번호가 없습니다.'
        //this.isMobpsValCheck = true
        this.fn_ConfirmMsg(this.isMobpsValMessge)
        return
      }
      mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '2' // 계약관계인구분코드 / 2:피보험자
      mobpsRegContPtcp.elstPartnNo = this.hoshInfoList[1].partnerId // 전자서명파트너번호
      mobpsRegContPtcp.mobslChnlCustId = this.hoshInfoList[1].agtFileId // 모바일영업채널고객ID
      mobpsRegContPtcp.mobslChnlCstgrId = this.hoshInfoList[1].detail.chnlCstgrId // 모바일영업채널고객그룹ID
      if (this.urlTrnsInsuredShow) {
        mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.hoshInfoList[1].detail.celno, '-', '') // 계약관계인전화번호암호화
      } else {
        mobpsRegContPtcp.contPtcpTelnoEncr = '' // 계약관계인전화번호암호화
      }
      mobpsRegContPtcp.contPtcpRrnEncr = this.hoshInfoList[1].custRrnId // 계약관계인주민등록번호암호화
      mobpsRegContPtcp.mobslChnlCustNm = this.hoshInfoList[1].insrdNm // 모바일영업채널고객명
      mobpsRegContPtcp.MobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 : 모름
      mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypInsured[0].key // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = this.kakaoIapDspchSnInsured // 알림톡알림발송순번

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      /** 계약관계자 구분코드 3:종피보험자 */
      if (this.showAddInsuredRow) {
        if (this.$bizUtil.isEmpty(this.lv_addInsured.custCelno)) {
          this.isMobpsValMessge = '종피보험자 휴대폰 번호가 없습니다.'
          //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
          return
        }
        mobpsRegContPtcp = {}
        mobpsRegContPtcp.contPtcpScCd = '3' // 계약관계인구분코드 / 3:종피보험자
        mobpsRegContPtcp.elstPartnNo = this.lv_addInsured.partnerId // 전자서명파트너번호
        mobpsRegContPtcp.mobslChnlCustId = this.lv_addInsured.chnlCustId // 모바일영업채널고객ID
        mobpsRegContPtcp.mobslChnlCstgrId = this.lv_addInsured.chnlCstgrId // 모바일영업채널고객그룹ID
        mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_addInsured.custCelno, '-', '') // 계약관계인전화번호암호화
        mobpsRegContPtcp.contPtcpRrnEncr = this.lv_addInsured.custRrnId // 계약관계인주민등록번호암호화
        mobpsRegContPtcp.mobslChnlCustNm = this.lv_addInsured.name // 모바일영업채널고객명
        mobpsRegContPtcp.MobpsContrRltnScCd = ' ' // 모바일청약계약자관계구분코드 : 모름
        mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypAddInsured[0].key // 모바일청약서명방법코드
        mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
        mobpsRegContPtcp.kakaoIapDspchSn = this.kakaoIapDspchSnAddInsured // 알림톡알림발송순번

        mobpsRegContPtcpList.push(mobpsRegContPtcp)
      }

      /** 계약관계자 구분코드 4:친권자1 */
      if (this.showPrtr1Row) {
        if (this.$bizUtil.isEmpty(this.lv_Prtr1Sel.chnlCustId)) {
          this.isMobpsValMessge = '친권자1을 선택하지 않았습니다.'
          //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
          return
        }
        if (this.$bizUtil.isEmpty(this.prtr1ContrFmrtSel.key)) {
          this.isMobpsValMessge = '친권자1의 관계를 선택하지 않았습니다.'
          //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
          return
        }
        if (this.$bizUtil.isEmpty(this.lv_Prtr1Sel.celno)) {
          this.isMobpsValMessge = '친권자1의 휴대폰 번호가 없습니다.'
          //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
          return
        }
        mobpsRegContPtcp = {}
        mobpsRegContPtcp.contPtcpScCd = '4' // 계약관계인구분코드 / 4:친권자1
        mobpsRegContPtcp.elstPartnNo = this.lv_Prtr1Sel.partnerId // 전자서명파트너번호
        mobpsRegContPtcp.mobslChnlCustId = this.lv_Prtr1Sel.chnlCustId // 모바일영업채널고객ID
        mobpsRegContPtcp.mobslChnlCstgrId = this.lv_Prtr1Sel.chnlCstgrId // 모바일영업채널고객그룹ID
        mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_Prtr1Sel.celno, '-', '') // 계약관계인전화번호암호화
        mobpsRegContPtcp.contPtcpRrnEncr = this.lv_Prtr1Sel.knb // 계약관계인주민등록번호암호화
        mobpsRegContPtcp.mobslChnlCustNm = this.lv_Prtr1Sel.name // 모바일영업채널고객명
        mobpsRegContPtcp.MobpsContrRltnScCd = this.prtr1ContrFmrtSel.key // 모바일청약계약자관계구분코드 / 20:부, 21:모
        mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
        mobpsRegContPtcp.kakaoIapDspchSn = this.kakaoIapDspchSnPrtr1 // 알림톡알림발송순번
        mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypPrtr1[0].key // 모바일청약서명방법코드

        mobpsRegContPtcpList.push(mobpsRegContPtcp)
      }

      /** 계약관계자 구분코드 5:친권자2 */
      if (this.showPrtr2Row || this.singlePrtrCheckbox) {
        // if ( this.prtr2ContrFmrtSel.key === '90') {
        if (this.singlePrtrCheckbox === true) {
          // 90:1인친권자
          mobpsRegContPtcp = {}
          mobpsRegContPtcp.contPtcpScCd = '5' // 계약관계인구분코드 / 5:친권자2
          mobpsRegContPtcp.elstPartnNo = '0000000000' // 전자서명파트너번호
          mobpsRegContPtcp.mobslChnlCustId = '00000000000000000000' // 모바일영업채널고객ID
          mobpsRegContPtcp.mobslChnlCstgrId = '00000000000000000000' // 모바일영업채널고객그룹ID
          mobpsRegContPtcp.contPtcpTelnoEncr = '' // 계약관계인전화번호암호화
          mobpsRegContPtcp.contPtcpRrnEncr = '0000001111111' // 계약관계인주민등록번호암호화
          mobpsRegContPtcp.mobslChnlCustNm = '친권2' // 모바일영업채널고객명
          mobpsRegContPtcp.MobpsContrRltnScCd = '90' // this.prtr2ContrFmrtSel.key // 모바일청약계약자관계구분코드 / 20:부, 21:모, 90:1인친권자
          mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부

          if (this.isCoprPrtrMuagr) { // 공동친권합의여부
            mobpsRegContPtcp.coprPrtrMuagrYn = 'Y' // 공동친권합의여부
          }

          mobpsRegContPtcp.kakaoIapDspchSn = 0 // 알림톡알림발송순번
          mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypPrtr2[0].key // 모바일청약서명방법코드
        } else {
          if (this.$bizUtil.isEmpty(this.lv_Prtr2Sel.chnlCustId)) {
            this.isMobpsValMessge = '친권자2을 선택하지 않았습니다.'
            //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
          if (this.$bizUtil.isEmpty(this.prtr2ContrFmrtSel.key)) {
            this.isMobpsValMessge = '친권자2의 관계를 선택하지 않았습니다.'
            //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }
          if (this.$bizUtil.isEmpty(this.lv_Prtr2Sel.celno)) {
            this.isMobpsValMessge = '친권자2의 휴대폰 번호가 없습니다.'
            //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
            return
          }

          mobpsRegContPtcp = {}
          mobpsRegContPtcp.contPtcpScCd = '5' // 계약관계인구분코드 / 5:친권자2
          mobpsRegContPtcp.elstPartnNo = this.lv_Prtr2Sel.partnerId // 전자서명파트너번호
          mobpsRegContPtcp.mobslChnlCustId = this.lv_Prtr2Sel.chnlCustId // 모바일영업채널고객ID
          mobpsRegContPtcp.mobslChnlCstgrId = this.lv_Prtr2Sel.chnlCstgrId // 모바일영업채널고객그룹ID
          mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_Prtr2Sel.celno, '-', '') // 계약관계인전화번호암호화
          mobpsRegContPtcp.contPtcpRrnEncr = this.lv_Prtr2Sel.knb // 계약관계인주민등록번호암호화
          mobpsRegContPtcp.mobslChnlCustNm = this.lv_Prtr2Sel.name // 모바일영업채널고객명
          mobpsRegContPtcp.MobpsContrRltnScCd = this.prtr2ContrFmrtSel.key // 모바일청약계약자관계구분코드 / 20:부, 21:모, 90:1인친권자
          mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부

          if (this.isCoprPrtrMuagr) { // 공동친권합의여부
            mobpsRegContPtcp.coprPrtrMuagrYn = 'Y' // 공동친권합의여부
          }

          mobpsRegContPtcp.kakaoIapDspchSn = this.kakaoIapDspchSnPrtr2 // 알림톡알림발송순번
          mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypPrtr2[0].key // 모바일청약서명방법코드
        }

        mobpsRegContPtcpList.push(mobpsRegContPtcp)
      }

      /** 계약관계자 구분코드 6:예금주 */
      if (this.$bizUtil.isEmpty(this.lv_dpstrSel.celno)) {
        this.isMobpsValMessge = '예금주의 휴대폰 번호가 없습니다.'
        //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
        return
      }
      mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '6' // 계약관계인구분코드 / 6:예금주
      mobpsRegContPtcp.elstPartnNo = this.lv_dpstrSel.partnerId // 전자서명파트너번호
      mobpsRegContPtcp.mobslChnlCustId = this.lv_dpstrSel.chnlCustId // 모바일영업채널고객ID
      mobpsRegContPtcp.mobslChnlCstgrId = this.lv_dpstrSel.chnlCstgrId // 모바일영업채널고객그룹ID
      mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_dpstrSel.celno, '-', '') // 계약관계인전화번호암호화
      mobpsRegContPtcp.contPtcpRrnEncr = this.lv_dpstrSel.knb // 계약관계인주민등록번호암호화
      mobpsRegContPtcp.mobslChnlCustNm = this.lv_dpstrSel.name // 모바일영업채널고객명
      mobpsRegContPtcp.MobpsContrRltnScCd = this.dPstrContrFmrtSel.key // 모바일청약계약자관계구분코드 / 01:본인, 02:배우자, 03:부모, 04:자녀
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = this.kakaoIapDspchSnDpstr // 알림톡알림발송순번

      // URL 순서 정하면서 알림톡 발송 방법 저장 필요
      if (this.lv_dpstrSel.chnlCustId === this.hoshInfoList[0].agtFileId) {
        // 예금주 = 계약자
        this.urlTrnsTypDpstr[0] = this.urlTrnsTypContr[0]
      } else if (this.lv_dpstrSel.chnlCustId === this.hoshInfoList[1].agtFileId) {
        // 예금주 = 피보험자
        this.urlTrnsTypDpstr[0] = this.urlTrnsTypInsured[0]
      }
      mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypDpstr[0].key // 모바일청약서명방법코드

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      /** 계약관계자 구분코드 9:FC */
      if (this.$bizUtil.isEmpty(this.lv_fc.fcCelno)) {
        this.isMobpsValMessge = 'FC의 휴대폰 번호가 없습니다.'
        //this.isMobpsValCheck = true
          this.fn_ConfirmMsg(this.isMobpsValMessge)
        return
      }
      mobpsRegContPtcp = {}
      mobpsRegContPtcp.contPtcpScCd = '9' // 계약관계인구분코드 / 9:FC
      mobpsRegContPtcp.contPtcpTelnoEncr = this.$bizUtil.replaceAll(this.lv_fc.fcCelno, '-', '') // 계약관계인전화번호암호화
      mobpsRegContPtcp.mobslChnlCustNm = this.lv_fc.fcNm // 모바일영업채널고객명
      mobpsRegContPtcp.mobpsSignMthCd = this.urlTrnsTypFC[0].key // 모바일청약서명방법코드
      mobpsRegContPtcp.coprPrtrMuagrYn = 'N' // 공동친권합의여부
      mobpsRegContPtcp.kakaoIapDspchSn = this.kakaoIapDspchSnFc // 알림톡알림발송순번

      mobpsRegContPtcpList.push(mobpsRegContPtcp)

      // 계약관계자 목록 저장
      param.mobpsRegContPtcpList = mobpsRegContPtcpList

      ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //AML 대응 처리 추가된 컬럼 값 셋팅 전자서명 완료시 부터 AML 처리 기준값 
      let isAmlCntr      = PSAmlUtil.fn_IsAmlPrfmc('1',this.lv_InputData.jsonInputData) // 계약자 AML 대상여부
      let isAmlPrtr01    = PSAmlUtil.fn_IsAmlPrfmc('2',this.lv_InputData.jsonInputData) // 친권자01 AML 대상여부
      let isAmlDthBnfr01 = PSAmlUtil.fn_IsAmlPrfmc('3',this.lv_InputData.jsonInputData) // 사망수익자01 AML 대상여부
      console.log('>>계약자 AML 대상여부:' + isAmlCntr)
      console.log('>>친권자01 AML 대상여부:' + isAmlPrtr01)
      console.log('>>사망수익자01 AML 대상여부:' + isAmlDthBnfr01)
      console.log('>>param.elstPartnNo :' + param.elstPartnNo)
      
      if(isAmlCntr) param.amlContrPartnNo	 = param.elstPartnNo // 계약자 BPID 셋팅
      if(isAmlPrtr01) param.amlPrtrPesonPartnNo = param.elstPartnNo // 정보확인단계에서 지정 선택된 친권자 BPID 셋팅 전자서명은 친권자 = 계약자 동일
      if(isAmlDthBnfr01) {
        //전산심사결과 조회 에서 수익자정보 셋팅함 this.amlBnfrInfoList = event.bnfrInfoList  
        let dthBnfr01Info = await PSAmlUtil.fn_GetBnfrInfo(this,this.amlBnfrInfoList)
        console.log('>>dthBnfr01Info.partnerId :' + dthBnfr01Info.partnerId)
        console.log('>>dthBnfr01Info.agtFileId :' + dthBnfr01Info.agtFileId)
        console.log('>>dthBnfr01Info.custNm :' + dthBnfr01Info.custNm)
        if(dthBnfr01Info){
          param.amlDthBnfrPartnNo	   = dthBnfr01Info.partnerId	//AML사망수익자파트너번호
          param.amlDthBnfrChnlCustId = dthBnfr01Info.agtFileId	//AML사망수익자채널고객ID
          param.amlDthBnfrCustNm	   = dthBnfr01Info.custNm	//AML사망수익자고객명
        }
      }
      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      //console.log('>>모청발행:' + JSON.stringify(param) )
      // return
      
      // TTSD02003
      param.jsonInputData = JSON.stringify(this.lv_InputData.jsonInputData) // OZ jsonInputData

      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList = [
          {stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo},
          {stndKeyId: 'cnsltNm', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.gssEplyNm},
          {stndKeyId: 'cnsltTel', stndKeyVal: this.lv_fc.fcCelno2}
        ]
      }
      
      this.post(lv_Vm, param, trnstId, auth, PSCommUtil.fn_getPostTssCommHeader(true))
        .then(function (response) {
          if (response.body != null) {
            // lv_Vm.isMobpsPblEnd = true
            // lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.mobpsPblEndMessage)
            //lv_Vm.fn_moveMobslList()
            
            // 사용자 이벤트 로그 기록 호출 함수
            // context : 호출 화면 this, curMobslSrnId : 호출화면ID, evtNm : 로그기록 이벤트명, vuchNo : 영수증번호
            PSCommUtil.prcsEvtLog(lv_Vm, 'MSPPS510M', '개인계약발송', lv_Vm.lv_InputData.moDCDocuMngVO.eltrnDoctId)
            
            lv_Vm.fn_BottomConfirmY(lv_Vm.mobpsPblEndMessage)
          } else {
            // 발행중 오류 발생시 메시지 표시
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      // alert('## 발송완료')  // 임시.주석삭제할것 
      console.log(lv_Vm + ' / ' + param + ' / ' + trnstId )
    },
    /******************************************************************************
    * Function명 : fn_ChkApcValidation
    * 설명       : 지정대리 청구인 유효값 체크 / (2024.04.23_PJO)
    ******************************************************************************/
    fn_ChkApcValidation () {
      let rst = true
      if (this.$bizUtil.isEmpty(this.apcPrsonAplScSel.key)) {
        this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인을 선택해 주세요. ') //문구수정(ASR231001107_231107_PJO)
        rst = false
        return false
      }

      // 계약자탭  show5 : false 열림 / true  닫힘
      if (this.show1 && this.simpAsgnClamAgntCd01 == 'B' && ( this.apcCustNm_simple1.trim() === '' || this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key))) {
        this.getStore('confirm').dispatch('ADD', '[계약자] 정보를 펼쳐 지정대리청구인 정보를 입력하세요!.') //문구수정(ASR231001107_231107_PJO)
        rst = false
        return false
      }

      // 1인
      if (this.isApcAsgnView) { // 지정대리청구인 선택여부 (ASR210500282_20210531_PJO)
        if ( this.simpAsgnClamAgntCd01 === '' ) {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인이 선택되지 않았습니다.')
          return false
        } else if ( this.simpAsgnClamAgntCd01 == 'A')  { // 일반입력
          // validation체크만함
          if (this.$bizUtil.isEmpty(this.lv_asgnProxyClamInfoSel.chnlCustId)) {
            this.isMobpsValMessge = '지정대리청구인을 선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            rst = false
            return false
          }
          if (this.$bizUtil.isEmpty(this.asgnProxyClamContrRltnScCdSel.key)) {
            this.isMobpsValMessge = '지정대리청구인의 피보험자와의 관계가<br>선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            rst = false
            return false
          }
        } else if ( this.simpAsgnClamAgntCd01 == 'B')  { // 직접입력
          if(this.$bizUtil.isEmpty(this.apcCustNm_simple1)) { //240402
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 성명이 입력되지 않았습니다.')
            rst = false
            return false
          }
          if (!this.$bizUtil.isCustNameValidation(this.apcCustNm_simple1.trim(), 'ZPER01')) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)')
            rst = false
            return false
          }
          if( this.$bizUtil.isEmpty(this.apclamInfoSel_simple1.key)) { // 240402
            this.getStore('confirm').dispatch('ADD', '지정대리청구인1 피보험자와의 관계가 선택되지 않았습니다.')
            rst = false
            return false
          }
        }
      }
      // 2인
      if (this.isApcAsgnView2) {
        if ( this.simpAsgnClamAgntCd02 === '' ) {
          this.getStore('confirm').dispatch('ADD', '지정대리청구인(2)이 선택되지 않았습니다.')
          return false
        } else if ( this.simpAsgnClamAgntCd02 == 'A')  {
          if (this.$bizUtil.isEmpty(this.lv_asgnProxyClamInfoSel2.chnlCustId)) {
            this.isMobpsValMessge = '지정대리청구인(2)을 선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            rst = false
            return false
          }
          if (this.$bizUtil.isEmpty(this.asgnProxyClamContrRltnScCdSel2.key)) {
            this.isMobpsValMessge = '지정대리청구인(2)의 피보험자와의 관계가<br>선택하지 않았습니다.'
            //this.isMobpsValCheck = true
            this.fn_ConfirmMsg(this.isMobpsValMessge)
            rst = false
            return false
          }
        } else if ( this.simpAsgnClamAgntCd02 == 'B')  { // 직접입력
          if( this.apcCustNm_simple2.trim() === '' ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2) 성명이 입력되지 않았습니다.')
            rst = false
            return false
          }
          if (!this.$bizUtil.isCustNameValidation(this.apcCustNm_simple2.trim(), 'ZPER01')) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2) 유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)')
            rst = false
            return false
          }
          if( this.$bizUtil.isEmpty(this.apclamInfoSel_simple2.key) ) {
            this.getStore('confirm').dispatch('ADD', '지정대리청구인(2) 피보험자와의 관계가 선택되지 않았습니다.')
            rst = false
            return false
          }
        }
      }
      return rst
    },
  /*---------------------------------------------------------------------------------
  * 기타 Util 영역
  ---------------------------------------------------------------------------------*/
  fn_ConfirmMsg(msg){
    this.getStore('confirm').dispatch('ADD', msg)
  },

  /******************************************************************************
  * Function명 : fn_CheckCustNm
  * 설명       : 신규일 때 고객명 입력 후 focus out 될 때 호출
  * 간편지대청 고객명 : *개인-내국인 고객명(간편지청 고객명) : 한글 10자 , 영문/숫자/특수문자/공란 불가 (간편BP채번시 고객명 체크규칙과 동일)
  ******************************************************************************/
  fn_CheckCustNm (param) { // 고객명 체크
    if ( param === '1')  {
      this.custNmErrorMsg = ''
      this.apcCustNm_simple1 = this.apcCustNm_simple1.trim()
      this.$refs.custNmRef1.clearValidation()
      if (this.$bizUtil.isEmpty(this.apcCustNm_simple1)) {
        this.custNmResult = false // 이름 유효성 체크 결과
        this.custNmErrorMsg = '필수입력항목입니다'
        this.$refs.custNmRef1.validate()
        return
      }
      if (this.$bizUtil.isCustNameValidation(this.apcCustNm_simple1, 'ZPER01')) {
        this.custNmResult = true // 이름 유효성 체크 결과
        this.custNmErrorMsg = ''
      } else {
        this.custNmResult = false // 이름 유효성 체크 결과
        this.custNmErrorMsg = '유효하지 않은 고객명입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)'
        this.$refs.custNmRef1.validate()
      }
      } else if ( param === '2')  {
      this.custNmErrorMsg2 = ''
      this.apcCustNm_simple2 = this.apcCustNm_simple2.trim()
      this.$refs.custNmRef2.clearValidation()

      if (this.$bizUtil.isEmpty(this.apcCustNm_simple2)) {
        this.custNmResult2 = false // 이름 유효성 체크 결과
        this.custNmErrorMsg2 = '필수입력항목입니다'
        this.$refs.custNmRef2.validate()
        return
      }
      if (this.$bizUtil.isCustNameValidation(this.apcCustNm_simple2, 'ZPER01')) {
        this.custNmResult2 = true // 이름 유효성 체크 결과
        this.custNmErrorMsg2 = ''
      } else {
        this.custNmResult2 = false // 이름 유효성 체크 결과
        this.custNmErrorMsg2 = '유효하지 않은 고객명(2)입니다.(한글10자이내, 영문/숫자/특수문자/공백 불가)'
        this.$refs.custNmRef2.validate()
      }
      }
    return
  },
    /******************************************************************************
    * 초회납입방법 신용카드납 on/off 체크 (ASR240400333_24.05 장한나)
      CARD_YN_CHK : Y / N  -  업무컨피그(초회보험료 신용카드)=Y &&  보험료납입유형값RD=C 인경우 가능
    ******************************************************************************/
    fn_CardYN () {
      let lv_Vm = this
      let btnId = 'S'
      let CARD_YN = ''
      lv_Vm.CARD_YN_CHK = 'N'
      let pParams = {}
      pParams = {envId: 'CARD_YN',sno: '1'} // 초회보험료 신용카드 가능여부
      this.post(lv_Vm, pParams, 'txTSSAD02S4', btnId)
        .then(function (response) {
        if (response.body !== null) {
           let returnVo = response.body
           if( !lv_Vm.$bizUtil.isEmpty(returnVo.envCntnt)) {// 값 ENV_CNTNT
            CARD_YN = returnVo.envCntnt
            if ( !lv_Vm.$bizUtil.isEmpty( lv_Vm.lv_InputData.zzPrmPadTVId ) &&  (lv_Vm.lv_InputData.zzPrmPadTVId === 'C' && CARD_YN === 'Y') ) {
              lv_Vm.CARD_YN_CHK = 'Y'
            }
          }
          console.log('CARD_YN:' + CARD_YN)
          console.log('CARD_YN_CHK:' + lv_Vm.CARD_YN_CHK)
        }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * 미지정/지정불가 선택
    * 디폴트값: 지정대리인 
    * ASR241100645_간편 지정대리청구인 넛지 (241203_PJO)
    ******************************************************************************/
    fn_ApcAlert1(){
      // console.log('디폴트값: 미지정/지정불가 선택')
      this.$refs.refApcAlertFlag.close()
      // 미지정/지정불가 시만 여기 로 들어옴 , 디폴트값 변경없습
    },
    /******************************************************************************
    * 지정 선택
    * 디폴트값: 1인지정,간편지정대리청구인
    * ASR241100645_간편 지정대리청구인 넛지 (241203_PJO)
    ******************************************************************************/
    fn_ApcAlert2(){
      // console.log('디폴트값: 1인지정,간편지정대리청구인')
      // 지정대리청구 값 초기화
      this.fn_Init_ApcHouseholdMember()
      this.$refs.refApcAlertFlag.close()
    },
  //--------------------------------------------------------------------------------
    _fn_End(){return}//코딩 종료 함수 마지막 메서드 함수 , 없음 주의
 },// methods

}//export default


</script>
<style scoped>
</style>